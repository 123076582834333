import BaseCrudService from "./baseCrud.service";

export default class CampaignService extends BaseCrudService {

	constructor() {
		super('Campaign');
	}

	listAllPaymentMethod(callback) {
		this.getRequest('ListAllPaymentMethod').then(callback);
	}

	listAllPaymentMethodProvider(callback){
		this.getRequest('ListAllPaymentMethodProviders').then(callback);
	}
	
	listAllDonationRecurrencePeriod(callback) {
		this.getRequest('ListAllDonationRecurrencePeriod').then(callback);
	}

	listAllDonationRecurrencePeriodByCampaign(callback) {
		this.getRequest('ListAllDonationRecurrencePeriodByCampaign').then(callback);
	}

	listAllTicketConfig(callback) {
		this.getRequest('FindAllTicketConfig').then(callback);
	}

	listConsentTermsByCampaignId(campaignId, callback) {
		this.getRequest('ListConsentTermsByCampaignId', {campaignId}).then(callback);
	}

	save(callback, campaign) {
		this.postRequest('Save', campaign).then(callback);
	}

	// Appreciation
	listAppreciationsByCampaignId(callback, campaignId) {
		this.getRequest('ListAppreciationsByCampaignId', { id: campaignId }).then(callback);
	}

	saveAppreciation(callback, campaignAppreciation) {
		this.postRequest('SaveAppreciation', campaignAppreciation).then(callback);
	}

	deleteAppreciation(callback, appreciationId) {
		this.deleteRequest('DeleteAppreciation', { id: appreciationId }).then(callback);
	}

	// Testimony
	listTestimoniesByCampaignId(callback, campaignId) {
		this.getRequest('ListTestimoniesByCampaignId', { id: campaignId }).then(callback);
	}

	saveTestimony(callback, testimony) {
		this.postRequest('SaveTestimony', testimony).then(callback);
	}

	deleteTestimony(callback, testimonyId) {
		this.deleteRequest('DeleteTestimony', { id: testimonyId }).then(callback);
	}

	// News
	listNewsByCampaignId(callback, campaignId) {
		this.getRequest('ListNewsByCampaignId', { id: campaignId }).then(callback);
	}

	saveNews(callback, news) {
		this.postRequest('SaveNews', news).then(callback);
	}

	deleteNews(callback, newsId) {
		this.deleteRequest('DeleteNews', { id: newsId }).then(callback);
	}

	// Image
	listImagesByCampaignId(callback, campaignId) {
		this.getRequest('ListImagesByCampaignId', { id: campaignId }).then(callback);
	}

	saveImage(callback, image) {
		this.postRequest('SaveImage', image).then(callback);
	}

	deleteImage(callback, imageId) {
		this.deleteRequest('DeleteImage', { id: imageId }).then(callback);
	}

	// Partner
	listPartnersByCampaignId(callback, campaignId) {
		this.getRequest('ListPartnersByCampaignId', { id: campaignId }).then(callback);
	}

	savePartner(callback, partner) {
		this.postRequest('SavePartner', partner).then(callback);
	}

	deletePartner(callback, partnerId) {
		this.deleteRequest('DeletePartner', { id: partnerId }).then(callback);
	}

	// Cases
	listCasesByCampaignId(callback, campaignId) {
		this.getRequest('ListCasesByCampaignId', { id: campaignId }).then(callback);
	}

	saveCase(callback, campaignCase) {
		this.postRequest('SaveCase', campaignCase).then(callback);
	}

	deleteCase(callback, caseId) {
		this.deleteRequest('DeleteCase', { id: caseId }).then(callback);
	}

	// Donation Steps
	listDonationStepsByCampaignId(callback, campaignId) {
		this.getRequest('ListDonationStepsByCampaignId', { id: campaignId }).then(callback);
	}

	saveDonationStep(callback, donationStep) {
		this.postRequest('SaveDonationStep', donationStep).then(callback);
	}

	deleteDonationStep(callback, donationStepId) {
		this.deleteRequest('DeleteDonationStep', { id: donationStepId }).then(callback);
	}

	// Photo cards
	listPhotoCardsByCampaignId(callback, campaignId) {
		this.getRequest('ListPhotoCardsByCampaignId', { id: campaignId }).then(callback);
	}

	savePhotoCards(callback, donationStep) {
		this.postRequest('SavePhotoCards', donationStep).then(callback);
	}

	deletePhotoCards(callback, donationStepId) {
		this.deleteRequest('DeletePhotoCards', { id: donationStepId }).then(callback);
	}

	getMetaSubdomainAndOrProjectId(subDomain){
		return this.getRequest("FindBySubdomainAndOrProjectId", {subdomain: subDomain}, true, true);
	}

	getSkin(callback) {
		this.getRequest("GetSkin").then(callback);
	}

	getCampaignLandingPageConfigBySubdomain(showLoader = true) {
		return this.getRequest("GetCampaignLandingPageConfigBySubdomain", null, true, showLoader);
	}

	calculateCampaignValues(campaignId) {
		return this.getRequest("CalculateCampaignValues", { campaignId: campaignId }, true, false);
	}

	getCampaignCaseList(campaignId) {
		return this.getRequest("GetCampaignCaseList", { campaignId: campaignId }, true, false);
	}

	getCampaignPhotoCardsList(campaignId) {
		return this.getRequest("GetCampaignPhotoCardsList", { campaignId: campaignId }, true, false);
	}

	getCampaignDonationStepList(campaignId) {
		return this.getRequest("GetCampaignDonationStepList", { campaignId: campaignId }, true, false);
	}

	getCampaignAppreciationList(campaignId) {
		return this.getRequest("GetCampaignAppreciationList", { campaignId: campaignId }, true, false);
	}

	getCampaignImageList(campaignId) {
		return this.getRequest("GetCampaignImageList", { campaignId: campaignId }, true, false);
	}

	getCampaignNewsList(campaignId) {
		return this.getRequest("GetCampaignNewsList", { campaignId: campaignId }, true, false);
	}

	getCampaignPartnerList(campaignId) {
		return this.getRequest("GetCampaignPartnerList", { campaignId: campaignId }, true, false);
	}

	getCampaignTestimonyList(campaignId) {
		return this.getRequest("GetCampaignTestimonyList", { campaignId: campaignId }, true, false);
	}
	
	getCampaignProjectList(campaignId, howMany, coverageName, causeId = 0) {
		return this.getRequest("GetCampaignProjectList", { campaignId: campaignId, howMany: howMany, coverageName: coverageName, causeId: causeId }, true, false);
	}

	getProjectCauseList(campaignId, coverageName) {
		return this.getRequest("GetProjectCauseList", { campaignId: campaignId, coverageName: coverageName }, true, false);
	}
	
	getProjectCoverageList(campaignId, causeId = 0) {
		return this.getRequest("GetProjectCoverageList", { campaignId: campaignId, causeId: causeId }, true, false);
	}

	getCampaignLandingPageConfigByCampaignId(campaignId) {
		return this.getRequest("GetCampaignLandingPageConfigByCampaignId", { campaignId: campaignId });
	}

	getCampaignLandingPageConfigByCampaignIdWithoutCarts(campaignId) {
		return this.getRequest("GetCampaignLandingPageConfigByCampaignIdWithoutCarts", { campaignId: campaignId });
	}

	GetCampaignApiList(campaignId){
		return this.getRequest("GetCampaignApiList", { campaignId: campaignId });
	}

	findById(callback, campaignId) {
		this.getRequest("FindById", { campaignId: campaignId }).then(callback);
	}

	listAll(entityId, companyId) {
		// if (companyId) {
			return this.getRequest('ListAllCampaigns', { entityId: entityId });
		// }
		// else {
		// 	return this.getRequest('ListAllCampaigns', { entityId: null });
		// }
	}

	listByQuery(query) {
		return this.getRequest('ListByQuery', { query: query });	
	}

	deleteCampaign(campaignId) {
		return this.deleteRequest('Delete', { id: campaignId });
	}

	publish(campaignId) {
		return this.postRequest('Publish', { campaignId: campaignId });
	}

	suspend(campaignId) {
		return this.postRequest('Suspend', { campaignId: campaignId }, false);
	}

	updateCapturedDonationValue(campaignId, capturedDonationValue) {
		return this.postRequest("UpdateCapturedDonationValue", { campaignId: campaignId, capturedDonationValue: capturedDonationValue }, false);
	}

	saveLandingPageConfig(campaignLandingPageConfig) {
		return this.postRequest("SaveLandingPageConfig", campaignLandingPageConfig, true, false);
	}

	saveDonationReceipt(campaignDonationReceipt) {
		return this.postRequest("SaveDonationReceipt", campaignDonationReceipt, true, false);
	}

	listFaqByCampaignId(callback, campaignId) {
		this.getRequest('ListFaqByCampaignId', { id: campaignId }).then(callback);
	}

	saveFaq(callback, faq) {
		this.postRequest('SaveFaq', faq).then(callback);
	}

	deleteFaq(callback, faqId) {
		this.deleteRequest('DeleteFaq', { id: faqId }).then(callback);
	}

	listGeneralFaq(callback) {
		this.getRequest('ListGeneralFaq').then(callback);
	}

	findBySubdomain(callback) {
		if (callback) {
			this.getRequest('FindBySubdomain').then(callback);
		}
		else{
			return this.getRequest('FindBySubdomain');
		}
	}

	saveCampaignSubdomain(campaignId, newSubdomain) {
		return this.postRequest('SaveCampaignSubdomain', { campaignId: campaignId, newSubdomain: newSubdomain });
	}

	listAllCampaignMixAndPublicByCompanyId(companyId){
		return this.getRequest('ListAllCampaignMixAndPublicByCompanyId', {companyId: companyId});
	}

	listAllByCompanyId(companyId){
		return this.getRequest("ListAllByCompanyId", {companyId: companyId});
	}
}