<template>
  <v-layout>
    <v-navigation-drawer
      :value="value"
      @input="changeValue"
      fixed
      temporary
      width="400"
      right
    >
      <v-layout header v-show="logged">
        <v-layout avatar p-relative>
          <div class="avatar-profile mr-3">
            <img :src="getUrl()" alt />
          </div>
          <div class="name-profile">
            <h2 v-if="person.socialReason">{{person.socialReason}}</h2>
            <h2 v-else>{{person.name}}</h2>
            <div>{{person.email}}</div>
            <div v-if="person.cnpj" class="notranslate">{{person.cnpj |FormatDocument('J')}}</div>
            <div v-else class="notranslate">{{person.cpf | FormatDocument}}</div>
            <div class="text-gray mt-1">
              <a
                @click="
                  logout();
                  $gtag('send', 'event', 'Navbar', 'clicou', 'Sair');
                "
              >
                <faIcon
                  type="fal"
                  name="sign-out"
                  size="16px"
                  class="mr-1"
                />Sair
              </a>
            </div>
          </div>
          <v-btn class="btn-close-drawer-mobile" @click="$emit('input', false)">
            <Icon :name="'fal fa-times'" :size="24" fontColor="#c8cbd9" />
          </v-btn>
        </v-layout>
      </v-layout>
      <v-divider></v-divider>

      <div class="scroll">
        <v-list>
          <v-list-tile v-for="line in getItems()" :key="line.title">
            <router-link
              :to="{ name: line.link }"
              class="align-center display-flex"
            >
              <v-list-tile-action>
                <faIcon type="fal" :name="line.faIcon" size="18px" />
              </v-list-tile-action>

              <v-list-tile-content>
                <v-list-tile-title>{{ line.title }}</v-list-tile-title>
              </v-list-tile-content>
            </router-link>
          </v-list-tile>
        </v-list>
        <h5 v-if="hasPermissionGestion">Gestão AUC</h5>
        <v-list v-if="hasPermissionGestion">
          <v-list-tile v-for="line in getItemsGestao()" :key="line.title">
            <router-link
              v-if="line.global"
              :to="{ name: line.link }"
              class="align-center display-flex"
            >
              <v-list-tile-action>
                <faIcon type="fal" :name="line.faIcon" size="18px" />
              </v-list-tile-action>

              <v-list-tile-content>
                <v-list-tile-title>{{ line.title }}</v-list-tile-title>
              </v-list-tile-content>
            </router-link>

            <router-link
              v-else
              :to="{
                name: line.link,
                query: { auc: true, entityId: entityIdAUC },
              }"
              class="align-center display-flex"
            >
              <v-list-tile-action>
                <faIcon type="fal" :name="line.faIcon" size="18px" />
              </v-list-tile-action>

              <v-list-tile-content>
                <v-list-tile-title>{{ line.title }}</v-list-tile-title>
              </v-list-tile-content>
            </router-link>
          </v-list-tile>
        </v-list>
        <div class="grupos" v-show="logged">
          <h5
            v-if="
              companyList.length > 0 ||
              institutionList.length > 0 ||
              aucList.length > 0 ||
              councilList.length > 0
            "
          >
            Meus Perfis
          </h5>
          <h6 v-if="councilList.length > 0">Conselhos</h6>
          <dl class="cons">
            <dd v-for="item in councilList" :key="item.id">
              <!-- <a class="-rh" @click="changeProfile(item)">{{item.name}}</a> -->
              <router-link
                class
                :to="{
                  name: routes.app.DashboardCompany,
                  query: { entityId: item.id },
                }"
                >{{ item.name }}</router-link
              >
            </dd>
          </dl>
          <h6 v-if="companyList.length > 0">Empresas</h6>
          <dl class="emp">
            <dd v-for="item in companyList" :key="item.id">
              <!-- <a class="-rh" @click="changeProfile(item)">{{item.name}}</a> -->
              <router-link
                class
                :to="{
                  name: routes.app.DashboardCompany,
                  query: { entityId: item.id },
                }"
                >{{ item.name }}</router-link
              >
            </dd>
          </dl>
          <h6 v-if="institutionList.length > 0">Instituições</h6>
          <dl class="inst">
            <dd v-for="item in institutionList" :key="item.id">
              <!-- <a class="-rh" @click="changeProfile(item)">{{item.name}}</a> -->
              <router-link
                class
                :to="{
                  name: routes.app.DashboardCompany,
                  query: { entityId: item.id },
                }"
                >{{ item.name }}</router-link
              >
            </dd>
          </dl>
          <h6 v-if="aucList.length > 0">Plataforma</h6>
          <dl class="inst">
            <dd v-for="item in aucList" :key="item.id">
              <!-- <a class="-rh" @click="changeProfile(item)">{{item.name}}</a> -->
              <router-link
                class
                :to="{
                  name: routes.app.DashboardCompany,
                  query: { entityId: item.id },
                }"
                >{{ item.name }}</router-link
              >
            </dd>
          </dl>
          <!-- <h6>Times</h6>
					<dl class="tim">
						<dd>
						<a href>Samsung</a>
						</dd>
						<dd>
						<a href>Adidas</a>
						</dd>
          </dl>-->
        </div>
      </div>
    </v-navigation-drawer>
  </v-layout>
</template>

<script type="plain/text">
import { Routes } from "@/scripts/models/enums/routes.enum";
import PersonService from "@/scripts/services/person.service.js";
import TokenService from "@/scripts/services/token.service";
import LoginService from "@/scripts/services/login.service";
import UserService from "@/scripts/services/user.service";
import Person from "@/scripts/models/person.model.js";
import Permissions from "@/scripts/models/enums/permissions.enum";
import {
  EntityType,
  EntityTypeList,
} from "@/scripts/models/enums/entityType.enum";
import { mapGetters, mapState, mapActions } from "vuex";
export default {
  props: ["value"],
  created() {
    this.loginService.verifyToken(this.verifyTokenCallback);
    this.entityType = EntityType;
    this.verifyPermissionGestion();
    this.routes = Routes;
  },
  data() {
    return {
      personService: new PersonService(),
      userService: new UserService(),
      loginService: new LoginService(),
      person: new Person(),
      //logged: false,
      hasPermissionGestion: false,
      listUserEntities: [],
      entityType: null,
      entityIdAUC: null,
      routes: "",
      institutionList: [],
      companyList: [],
      aucList: [],
      councilList: [],
      itemsLogged: [
        {
          title: "Minhas Doações",
          faIcon: "presentation",
          link: Routes.app.DashboardPF,
        },
        {
          title: "Meus Dados",
          faIcon: "user-edit",
          link: Routes.web.Profile,
        },
        //,
        // {
        //   title: "Metodos de Pagamento",
        //   faIcon: "credit-card",
        //   link: Routes.web.PaymentMethods
        // }
      ],
      itemsLogout: [
        {
          // coloquei para testes por enquanto
          title: "Login",
          faIcon: "log-in",
          link: Routes.web.Login,
        },
        {
          title: "Cadastrar-se",
          faIcon: "log-in",
          link: Routes.web.Login,
        },
      ],
      itemsGestao: [
        {
          title: "Conselhos",
          faIcon: "fal fa-atlas",
          link: Routes.app.ManagementCouncil,
        },
        {
          title: "Empresas",
          faIcon: "user-tie",
          link: Routes.app.ManagementCompany,
        },
        {
          title: "Instituições",
          faIcon: "hands",
          link: Routes.app.ManagementInstitution,
        },
        {
          title: "Usuários",
          faIcon: "users-cog",
          link: Routes.app.ManagementUser,
        },
        {
          title: "Projetos",
          faIcon: "box-heart",
          link: Routes.app.ProjectList,
        },
        {
          title: "Voluntariado",
          faIcon: "hand-holding-heart",
          link: Routes.app.VolunteeringList,
        },
        {
          title: "Campanhas",
          faIcon: "bullhorn",
          link: Routes.app.CampaignList,
        },
        // {
        //   title: "Boletos",
        //   faIcon: "barcode-read",
        //   link: Routes.app.ManagementBankSlip
        // },
        {
          title: "Taxas",
          faIcon: "money-bill-alt",
          link: Routes.app.ManagementTax,
          global: true,
        },
        {
          title: "Convênios",
          faIcon: "id-card",
          link: Routes.app.ManagementTicket,
          global: true,
        },
        {
          title: "Metas",
          faIcon: "calendar-check",
          link: Routes.app.ManagementGoal,
          global: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["hasPermission", "logged"]),
    ...mapState(["permissionsLoaded"]),
  },
  watch: {
    permissionsLoaded(newValue, oldValue) {
      if (newValue === true && oldValue === false) {
        this.verifyPermissionGestion();
      }
    },
    logged(newValue, oldValue) {
      if (newValue === true && oldValue === false) {
        this.verifyTokenCallback(true);
      }
    },
    person() {
      this.userService.listUserEntities().then(this.listUserEntitiesCallback);
    },
  },
  methods: {
    ...mapActions(["setLogged", "setUserProfile"]),
    resetRecaptcha(){
      try {
        if (window.grecaptcha)
        {
          window.grecaptcha.reset();       
        }
      } catch (error) {
        
      }
    },
    verifyTokenCallback(data) {
      if (TokenService.getToken() && data) {
        this.personService.getProfile(this.getProfileCallback);

        //this.setLogged(true);
      } else {
        //this.setLogged(false);
      }
    },
    verifyPermissionGestion() {
      this.hasPermissionGestion = this.hasPermission(
        [
          Permissions.CRUDInstitution,
          Permissions.CRUDCompany,
          Permissions.CRUDUserAll,
        ],
        null,
        true
      );
    },
    changeValue(e) {
      this.$emit("input", e);
    },
    getUrl() {
      let url =
        "https://ui-avatars.com/api/?font-size=0.40&background=F1F2F5&color=80849A&size=70&name=";
      if (this.person != null && !this.person.cnpj) 
        url += this.person.name.split().join("+");
      else if(this.person != null && this.person.cnpj)
        url += this.person.socialReason.split().join("+");
      return url;
    },
    getProfileCallback(data) {
      this.person = data;
      this.setUserProfile(this.person);
    },
    logout() {
      this.changeValue(false);
      TokenService.removeToken();
      this.$store.commit("REMOVE_ROLES");
      this.resetRecaptcha()

      if (this.$router.currentRoute.name != Routes.app.CampaignLandingPage) {
        this.$router.push({
          name: Routes.app.CampaignLandingPage,
        });
      }

      this.setLogged(false);
    },
    getItems() {
      return this.logged ? this.itemsLogged : this.itemsLogout;
    },
    getItemsGestao() {
      return this.itemsGestao;
    },
    listUserEntitiesCallback(data) {
      this.listUserEntities = data;

      this.institutionList = this.listUserEntities.filter(
        this.filterByTypeAndPermission.bind(this, this.entityType.Institution)
      );
      this.companyList = this.listUserEntities.filter(
        this.filterByTypeAndPermission.bind(this, this.entityType.Company)
      );
      this.aucList = this.listUserEntities.filter(
        this.filterByTypeAndPermission.bind(this, this.entityType.AUC)
      );
      this.councilList = this.listUserEntities.filter(
        this.filterByTypeAndPermission.bind(this, this.entityType.Council)
      );

      let tam = this.listUserEntities.filter(
        this.filterByTypeAndPermission.bind(this, this.entityType.AUC)
      ).length;
      if (tam > 0) {
        this.entityIdAUC = this.listUserEntities.filter(
          this.filterByTypeAndPermission.bind(this, this.entityType.AUC)
        )[0].id;
      }
    },
    filterByTypeAndPermission(currentType, item) {
      return (
        item.type === currentType &&
        this.hasPermission(
          [
            Permissions.CRUDInstitution,
            Permissions.CRUDCompany,
            Permissions.CRUDCouncil,
            Permissions.CRUDProject,
            Permissions.CRUDCampaign,
            Permissions.UpdateInstitution,
            Permissions.UpdateCompany,
            Permissions.UpdateCouncil,
          ],
          item.id,
          false
        )
      );
    },
    changeProfile(entity) {
      this.$router.push({
        name: Routes.app.DashboardCompany,
        query: { entityId: entity.id },
      });
    },
  },
};
</script>
