<template>
  <div
    v-if="useBg"
    class="img-container icon-white"
    :style="{backgroundImage: 'url(' + getImage() + ')'}"
  ></div>
  <img v-else :src="image" :alt="alt" loading="lazy" />
</template>

<script>
import FileService from "@/scripts/services/file.service";
export default {
  props: {
    file: null,
    alt: "",
    useBg: false,
    effects: null
  },
  data() {
    return {
      fileService: new FileService(),
      image: ''
    };
  },
  created() {
    this.image = this.getImage();
  },
  watch: {
    file(val) {
      this.image = this.getImage();
    }
  },
  methods: {
    renderImage() {
      if (this.file != null && !this.file.loading && this.file.id) {
        this.file.loading = true;
        this.fileService.render(this.file.id).then(this.renderCallback);
      }
    },
    renderCallback(response) {
      this.$set(this.file, "imageContent", response);
      this.file.loading = false;
      this.$emit("loaded");
    },
    getImage() {
      if (this.file != null && this.file.hasOwnProperty("imageContent")) {
        if (this.file.imageContent) 
          return this.file.imageContent;
        return "";
      } else if (this.file != null && this.file.public) {
        // if(this.effects){
        //   var caminho = 'image/upload/',
        //       urlEffects = this.effects,
        //       urlImage = this.file.path,
        //       array = urlImage.split(caminho);
        //       urlImage = [array[0],caminho,urlEffects,'/',array[1]].join('');
        //   return urlImage;
        // }
        // else
        return this.file.path;
      } else if (this.file && !this.file.loading) {
        this.renderImage();
      }
      return "";
    }
  }
};
</script>