import BaseCrudService from "./baseCrud.service";

export default class CheckoutService extends BaseCrudService {

    constructor() {
        super('Checkout');
    }

    getCampaignDataForCheckout(){
        return this.getRequest('GetCampaignDataForCheckout')
    }
    
}