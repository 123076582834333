const SocialMedia = {
    Whatsapp: {
        id: 0,
        incentivado: "Eu fiz uma doação e gostaria de dividir com tod@s essa iniciativa. Exerça sua cidadania e destine parte do seu I.R. para instituições sociais que estão transformando vidas. [CAMPANHA]",
        semIncentivo: "Eu fiz uma doação e gostaria de dividir com tod@s essa iniciativa. Exerça sua cidadania e apoie as instituições sociais que estão transformando vidas. [CAMPANHA]",
        projeto: "Texto simplificado para testes de compartilhamento de Projetos. [CAMPANHA]",
        vazio: "[CAMPANHA]",
        url: "https://wa.me/?text=[TEXT]"
    },
    Facebook: {
        id: 1,
        incentivado: "Eu fiz uma doação e gostaria de dividir com tod@s essa iniciativa. Exerça sua cidadania e destine parte do seu I.R. para instituições sociais que estão transformando vidas.",
        semIncentivo: "Eu fiz uma doação e gostaria de dividir com tod@s essa iniciativa. Exerça sua cidadania e apoie as instituições sociais que estão transformando vidas.",
        projeto: "Texto simplificado para testes de compartilhamento de Projetos",
        vazio: "",
        url: "https://www.facebook.com/sharer/sharer.php?quote=[TEXT]&u=[URL]"
    },
    Twitter: {
        id: 2,
        incentivado: "Eu fiz uma doação e gostaria de dividir com tod@s essa iniciativa. Exerça sua cidadania e destine parte do seu I.R. para instituições sociais que estão transformando vidas.",
        semIncentivo: "Eu fiz uma doação e gostaria de dividir com tod@s essa iniciativa. Exerça sua cidadania e apoie as instituições sociais que estão transformando vidas.",
        projeto: "Texto simplificado para testes de compartilhamento de Projetos",
        vazio: "",
        url: "https://twitter.com/intent/tweet?text=[TEXT]&url=[URL]"
    },
    Linkedin: {
        id: 3,
        incentivado: "Eu fiz uma doação e gostaria de dividir com tod@s essa iniciativa. Exerça sua cidadania e destine parte do seu I.R. para instituições sociais que estão transformando vidas.",
        semIncentivo: "Eu fiz uma doação e gostaria de dividir com tod@s essa iniciativa. Exerça sua cidadania e apoie as instituições sociais que estão transformando vidas.",
        projeto: "Texto simplificado para testes de compartilhamento de Projetos",
        vazio: "Compartilhe",
        url: "https://www.linkedin.com/shareArticle?mini=true&title=[TEXT]&url=[URL]"
    },
    Instagram: {
        id: 4,
        incentivado: "Eu fiz uma doação e gostaria de dividir com tod@s essa iniciativa. Exerça sua cidadania e destine parte do seu I.R. para instituições sociais que estão transformando vidas.",
        semIncentivo: "Eu fiz uma doação e gostaria de dividir com tod@s essa iniciativa. Exerça sua cidadania e apoie as instituições sociais que estão transformando vidas.",
        projeto: "Texto simplificado para testes de compartilhamento de Projetos",
        vazio: "",
        url: ""
    }
};

const GetSocialMediaLink = function (socialMedia, campaign, kindOfText, alternativeLink = null) {
    var redirectLink = '';
    if (alternativeLink) {
        redirectLink = alternativeLink;
    }
    else {
        var host = window.location.host.split(".").slice(1).join(".");
        redirectLink = window.location.protocol + "//" + campaign.subdomain + "." + host;
    }

    switch (socialMedia) {
        case SocialMedia.Whatsapp:
            return socialMedia.url.replace('[TEXT]', encodeURIComponent(socialMedia[kindOfText].replace('[CAMPANHA]', redirectLink)));
        case SocialMedia.Facebook:
            return "javascript:window.open('" + socialMedia.url.replace('[TEXT]', encodeURIComponent(socialMedia[kindOfText])).replace('[URL]', encodeURIComponent(redirectLink)) + "','', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600')";
        case SocialMedia.Twitter:
            return "javascript:window.open('" + socialMedia.url.replace('[TEXT]', encodeURIComponent(socialMedia[kindOfText])).replace('[URL]', encodeURIComponent(redirectLink)) + "')";
        case SocialMedia.Linkedin:
            return "javascript:window.open('" + socialMedia.url.replace('[TEXT]', encodeURIComponent(socialMedia[kindOfText])).replace('[URL]', encodeURIComponent(redirectLink)) + "','', '&source=MadeInTheMidlands&target=new,height=600,width=600')";
    }

    // if (hasProjectIncentivized) {
    //     switch (socialMedia) {
    //         case SocialMedia.Whatsapp:
    //             return socialMedia.url.replace('[TEXT]', encodeURIComponent(socialMedia.incentivado.replace('[CAMPANHA]', campaignLink)));
    //         case SocialMedia.Facebook:
    //             return "javascript:window.open('" + socialMedia.url.replace('[TEXT]', encodeURIComponent(socialMedia.incentivado)).replace('[URL]', encodeURIComponent(campaignLink)) + "','', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600')";
    //         case SocialMedia.Twitter:
    //             return "javascript:window.open('" + socialMedia.url.replace('[TEXT]', encodeURIComponent(socialMedia.incentivado)).replace('[URL]', encodeURIComponent(campaignLink)) + "')";
    //         case SocialMedia.Linkedin:
    //             return "javascript:window.open('" + socialMedia.url.replace('[TEXT]', encodeURIComponent(socialMedia.incentivado)).replace('[URL]', encodeURIComponent(campaignLink)) + "','', '&source=MadeInTheMidlands&target=new,height=600,width=600')";
    //     }
    // } else {
    //     switch (socialMedia) {
    //         case SocialMedia.Whatsapp:
    //             return socialMedia.url.replace('[TEXT]', encodeURIComponent(socialMedia.semIncentivo.replace('[CAMPANHA]', campaignLink)));
    //         case SocialMedia.Facebook:
    //             return "javascript:window.open('" + socialMedia.url.replace('[TEXT]', encodeURIComponent(socialMedia.semIncentivo)).replace('[URL]', encodeURIComponent(campaignLink)) + "','', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600')";
    //         case SocialMedia.Twitter:
    //             return "javascript:window.open('" + socialMedia.url.replace('[TEXT]', encodeURIComponent(socialMedia.semIncentivo)).replace('[URL]', encodeURIComponent(campaignLink)) + "')";
    //         case SocialMedia.Linkedin:
    //             return "javascript:window.open('" + socialMedia.url.replace('[TEXT]', encodeURIComponent(socialMedia.semIncentivo)).replace('[URL]', encodeURIComponent(campaignLink)) + "','', '&source=MadeInTheMidlands&target=new,height=600,width=600')";
    //     }
    // }
}

const LoginSocialMedia = {
    Facebook: 1,
    Google: 2
};

export { SocialMedia, GetSocialMediaLink, LoginSocialMedia };