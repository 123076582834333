<template>
  <div class="display-flex justify-content-center">
    <div v-if="!rounded" :class="defaultClass" id="preview" ref="preview" :style="{width , backgroundImage: 'url(' + getImage() + ')'}">
      <label for="upload-input">
        <i class="fas fa-camera-alt"></i>
      </label>
      <label for="upload-input" class="do-upload" v-show="file.path == ''">
        <i class="fal fa-cloud-upload"></i>
        {{unselectedText}}
      </label>
      <input
        type="file"
        id="upload-input"
        accept="image/*"
        @change="fileChanged($event)"
        ref="upload_input"
      />
    </div>
    <div v-else class="upload-image rounded" id="preview" ref="preview" :style="{backgroundImage: 'url(' + getImage() + ')'}">
      <label for="upload-input" class="d-none">
        <i class="fas fa-camera-alt"></i>
      </label>
      <label for="upload-input" class="do-upload" v-show="file.path == ''">
        <i class="fal fa-cloud-upload"></i>
        {{unselectedText}}
      </label>
      <input
        type="file"
        id="upload-input"
        accept="image/*"
        @change="fileChanged($event)"
        ref="upload_input"
      />
    </div>
  </div>
</template>

<script type="plain/text">
// Documentação https://github.com/xkeshi/image-compressor
import ImageCompressor from "image-compressor.js";

export default {
  props: {
    unselectedText: {
      type: String,
      default: ''
    },
    rounded:Boolean,
    defaultClass: {
      type: String,
      default: 'upload-image bg-contain'
    },
    quickName: String,
    file: Object,
    maxWidth: {
      type: Number,
      default: 1000
    },
    maxHeight: {
      type: Number,
      default: 1000
    },
    minWidth: {
      type: Number,
      default: 0
    },
    minHeight: {
      type: Number,
      default: 0
    },
    quality: {
      type: Number,
      default: 0.65
    },
    width:{
      type: String,
      default: "300px"
    }
  },
  data() {
    return {
      loader: false
    };
  },
  methods: {
    getImage(){
      return this.quickName ? 'https://ui-avatars.com/api/?background=F1F2F5&color=80849A&size=256&name=' + this.quickName : this.file.path;
    },
    fileChanged(event) {
      var selectedFile = event.target.files[0];
      this.loader = true;
      new ImageCompressor(selectedFile, {
        quality: this.quality,
        maxWidth: this.maxWidth,
        maxHeight: this.maxHeight,
        minWidth: this.minWidth,
        minHeight: this.minHeight,
        success: function(result) {
          this.file.name = result.name;
          let reader = new FileReader();
          reader.onload = function() {
            this.file.path = reader.result;
          }.bind(this);
          reader.readAsDataURL(result);
          this.loader = false;
        }.bind(this),
        error(e) {
          console.log(e.message);
        }
      });
    }
  }
};
</script>