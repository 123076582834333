<template>
  <v-menu
    class="carts"
    :close-on-content-click="false"
    attach
    :nudge-width="400"
    :nudge-bottom="70"
    :nudge-left="320"
    absolute
    transition="slide-y-transition"
  >
    <template v-slot:activator="{ on }">
      <a href="javascript:;" class="c-white" v-on="on">
        <i class="uil uil-bell" style="font-size:24px"></i>
      </a>
    </template>
    <v-navigation-drawer :value="true" @input="true" fixed temporary width="400" right>
      <v-layout header v-show="logged">
        <v-layout avatar>
          <div class="warning-central-icon">
            <i class="fal fa-bell fa-2x text-gray"></i>
          </div>
          <div class="headline white">
            <h1>Central de Avisos</h1>
          </div>
        </v-layout>
      </v-layout>
      <v-divider></v-divider>

      <div class="scroll">
        <h1 class="warning-uppercase-text ml-4 mt-3 mb-4">Recentes (4)</h1>

        <div class="notification-card mt-4 mb-4">
          <div class="card-icon">
            <i class="fal fa-shopping-cart fa-2x text-red"></i>
          </div>
          <div class="card-description">
            <span>Você tem um carrinho pendente da campanha Anjo da Guarda.</span>
            <span class="text-action text-red">Continuar para o carrinho</span>
          </div>
          <div class="card-buttons">
            <div>
              <i class="fas fa-circle text-primary"></i>
            </div>
            <div>
              <i class="fal fa-trash"></i>
            </div>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="notification-card mt-4 mb-4">
          <div class="card-icon">
            <i class="fal fa-file-exclamation fa-2x text-yellow"></i>
          </div>
          <div class="card-description text-action">
            <span>O estatuto da Instituição Instituto Beatriz e Lauro Fiuza está prestes a vencer.</span>
            <span class="text-yellow">Atualizar documento</span>
          </div>
          <div class="card-buttons">
            <div>
              <i class="fas fa-circle text-primary"></i>
            </div>
            <div>
              <i class="fal fa-trash"></i>
            </div>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="notification-card mt-4 mb-4">
          <div class="card-icon">
            <i class="fal fa-user-tag fa-2x text-gray-dark"></i>
          </div>
          <div class="card-description">
            <span>Você ainda não completou os dados do seu perfil.</span>
            <span class="text-action text-gray-dark">Completar meu perfil</span>
          </div>
          <div class="card-buttons">
            <div>
              <i class="fal fa-circle"></i>
            </div>
            <div>
              <i class="fal fa-trash"></i>
            </div>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="notification-card mt-4 mb-4">
          <div class="card-icon">
            <i class="fal fa-user-tie fa-2x text-gray-dark"></i>
          </div>
          <div class="card-description">
            <span>Você ainda não completou o perfil da empresa Samsung.</span>
            <span class="text-action text-gray">Completar o perfil da empresa</span>
          </div>
          <div class="card-buttons">
            <div>
              <i class="fal fa-circle"></i>
            </div>
            <div>
              <i class="fal fa-trash"></i>
            </div>
          </div>
        </div>
        <v-divider></v-divider>

        <h1 class="warning-uppercase-text ml-4 mt-3 mb-4">Demais (12)</h1>
        <div class="notification-card mt-4 mb-4">
          <div class="card-icon">
            <i class="fal fa-shopping-cart fa-2x text-red"></i>
          </div>
          <div class="card-description">
            <span>Você tem um carrinho pendente da campanha Anjo da Guarda.</span>
            <span class="text-action text-red">Continuar para o carrinho</span>
          </div>
          <div class="card-buttons">
            <div>
              <i class="fal fa-circle"></i>
            </div>
            <div>
              <i class="fal fa-trash"></i>
            </div>
          </div>
        </div>
        <v-divider></v-divider>
      </div>
    </v-navigation-drawer>
  </v-menu>
</template>

<script type="plain/text">
export default {
  props: ["value"],
  methods: {
    changeValue(e) {
      this.$emit("input", e);
    }
  }
};
</script>

<style>
</style>
