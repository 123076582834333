import CheckoutService from "@/scripts/services/checkout.service";
import CartService from "@/scripts/services/cart.service";
import CampaignCheckout from "@/scripts/models/checkout/campaignCheckout.model";
import Checkout from "@/scripts/models/checkout/checkout.model";
import USDDonationCheckout from "@/scripts/models/checkout/usdDonationCheckout.model";

const CheckoutModule = {
    state: {
        service: new CheckoutService(),
        cartService: new CartService(),
        campaign: new CampaignCheckout(),
        usdDonation: new USDDonationCheckout(),
        checkout: new Checkout()
    },
    mutations: {
        CAMPAIGN_CHECKOUT_DATA: (state, payload) => {
            state.campaign = payload
        },
        SET_USD_DONATION: (state, payload) => {
            state.usdDonation = payload
        },
        SET_HAS_PROJECT_INCETIVED: (state, payload) => {
            state.hasProjectIncetived = payload
        },
    },
    getters: {
        campaignCheckoutData: state => {
            return state.campaign
        },
        usdDonationInfo: state => {
            return state.usdDonation
        },
        hasProjectIncetived: state => {
            return state.hasProjectIncetived
        }
    },
    actions: {
        loadCampaignCheckoutData: async state => {
            try {
                if (state.state.campaign.id != 0) return;
                var campaignData = await state.state.service.getCampaignDataForCheckout();
                state.commit('CAMPAIGN_CHECKOUT_DATA', campaignData)
            } catch (error) {
                throw new Error("Erro ao carregar dados do checkout");
            }
        },
        toggleUSDDonation: async (state, enabled) => {
            try {
                if (enabled) {
                    var dollar = await state.state.cartService.getDolarPrice();
                    var usDonation = new USDDonationCheckout();
                    usDonation.enabled = enabled;
                    usDonation.dollar = dollar.bid;
                    usDonation.coin = 'USD';
                    usDonation.prefix = 'USD$'
                    state.commit('SET_USD_DONATION', usDonation)
                } else
                    state.commit('SET_USD_DONATION', new USDDonationCheckout())
            } catch (error) {
                throw new Error("Erro ao trocar moeda");
            }
        },
        checkIfHasIncetivedProject: async state => {
            try {
                console.log(state)
                var cartItems = await state.state.cartService.getCart()
                
                const value = cartItems
                    .map(function (el) {
                        return el.project.incentiveLawId;
                    })
                    .filter(function (r) {
                        return r != null;
                    }).length > 0;

                state.commit('SET_HAS_PROJECT_INCETIVED', value)
            } catch (error) {
                throw new Error("Erro ao carregar dados do carrinho");
            }
        }
    }
}

export default CheckoutModule;