const HTTP_ERROR_STATUS = 500;
const HTTP_SUCCESS_STATUS = 200;
const HTTP_UNAUTHORIZED_STATUS = 401;
const HTTP_NOT_FOUND_STATUS = 404;

import Vue from 'vue';
import ApiService from './api.service';
import TokenService from './token.service';
import Router from '../../router';
import CommonHelper from '@/scripts/helpers/common.helper';
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import store from '@/store';

export default class BaseService {

	constructor(controllerName) {
		this.controllerName = controllerName + '/';
		this.router = Router;
	}

	hideLoader() {
		store.commit("SET_LOADER", false);
	}

	showLoader() {
		store.commit("SET_LOADER", true);
	}

	swal(title, message, type) {
		CommonHelper.swal(title, message, type);
	}

	handleError(baseResponse, showMessage) {

		if (baseResponse && baseResponse.errorMessage) {
			Vue.$log.error(baseResponse.errorMessage);
			
			if (showMessage) {

				this.swal("Desculpe, estamos com dificuldades", baseResponse.errorMessage, "error");
			}
		}
		else {
			if (showMessage) {
				this.swal('Desculpe, houve um erro inesperado.');
			}
		}

		throw baseResponse;
	}

	handleSuccess(baseResponse, showMessage) {


		//Se existir uma mensagem customizada
		if (baseResponse.customMessage) {
			if (showMessage) {
				this.swal("Sucesso!", baseResponse.customMessage, "success");
			}
		}

		return baseResponse.data;
	}

	handleToken(baseResponse) {
		if (baseResponse && baseResponse.token) {
			TokenService.saveToken(baseResponse.token);
			//store.commit("AUTHENTICATE", true);
		}
	}

	handleReturn(response, showMessage, showLoader) {
		if (showLoader)
			this.hideLoader();
		if (response.response !== undefined) {
			response = response.response;
		}
		if (response.status == HTTP_SUCCESS_STATUS) {
			this.handleToken(response.data);
			return this.handleSuccess(response.data, showMessage);
		}
		else if (response.status == HTTP_UNAUTHORIZED_STATUS) {
			let route = this.router.app.$route;
			if (route.name != Routes.web.Login) {
				if (route.query.return == null) {
					let query = {};
					for (var prop in route.query) {
						if (route.query.hasOwnProperty(prop)) {
							query[prop] = route.query[prop];
						}
					}
					query.return = true;
					//this.router.push({ name: Routes.web.Login, query: { returnRoute: route.name, returnQuery: query } });
					store.commit("SHOW_LOGIN");
				}
				else {
					//this.router.push({ name: Routes.web.Login });
					store.commit("SHOW_LOGIN");
				}
			}
			//store.commit("AUTHENTICATE", false);
		}
		else {
			return this.handleError(response.data, showMessage);
		}
	}

	putRequest(action, data, showMessage = true, showLoader = true) {
		if (showLoader)
			this.showLoader();

		const url = this.controllerName + action

		return ApiService.put(url, data)
			.then(function (response) {

				//logging request
				Vue.$log.debug(this.mountRequestData(url, data, response.data, response.status));

				return this.handleReturn(response, showMessage, showLoader);
			}.bind(this))
			.catch(function (response) {
				return this.handleReturn(response, showMessage, showLoader);
			}.bind(this));
	}

	postRequest(action, data, showMessage = true, showLoader = true) {
		
		window.console.log('let me check this: ' + showMessage);
		
		if (showLoader)
			this.showLoader();

		const url = this.controllerName + action;

		return ApiService.post(url, data)
			.then(function (response) {
				//logging request
				Vue.$log.debug(this.mountRequestData(url, data, response.data, response.status));

				return this.handleReturn(response, showMessage, showLoader);
			}.bind(this))
			.catch(function (response) {
				return this.handleReturn(response, showMessage, showLoader);
			}.bind(this));
	}

	postFileRequest(action, data, showMessage = true, showLoader = true) {
		if (showLoader)
			this.showLoader();

		return ApiService.postFiles(this.controllerName + action, data)
			.then(function (response) {
				return this.handleReturn(response, showMessage, showLoader);
			}.bind(this))
			.catch(function (response) {
				return this.handleReturn(response, showMessage, showLoader);
			}.bind(this));
	}
	postRequestBlob(action, data, showMessage = true, showLoader = true) {
		if (showLoader)
			this.showLoader();
		let options = {
			method: 'post',
			url: this.controllerName + action,
			data: data,
			responseType: 'blob'
		}
		return ApiService.requestWithOptions(options)
			.then(function (response) {
				this.hideLoader();
				return response;
			}.bind(this))
			.catch(function (response) {
				return this.handleReturn(response, showMessage, showLoader);
			}.bind(this));
	}

	getRequestBlob(action, data, showMessage = true, showLoader = true) {
		if (showLoader)
			this.showLoader();
		let options = {
			method: 'get',
			url: this.controllerName + action,
			params: data,
			responseType: 'blob'
		}
		return ApiService.requestWithOptions(options)
			.then(function (response) {
				this.hideLoader();
				return response;
			}.bind(this))
			.catch(function (response) {
				return this.handleReturn(response, showMessage, showLoader);
			}.bind(this));
	}


	deleteRequest(action, data, showMessage = true, showLoader = true) {
		if (showLoader)
			this.showLoader();

		const url = this.controllerName + action;

		return ApiService.delete(url, data)
			.then(function (response) {

				//logging request
				Vue.$log.debug(this.mountRequestData(url, data, response.data, response.status));

				return this.handleReturn(response, showMessage, showLoader);
			}.bind(this))
			.catch(function (response) {
				return this.handleReturn(response, showMessage, showLoader);
			}.bind(this));
	}

	getRequest(action, data, showMessage = true, showLoader = true) {
		if (showLoader)
			this.showLoader();

		const url = this.controllerName + action;

		return ApiService.get(url, data)
			.then(function (response) {

				//logging request
				Vue.$log.debug(this.mountRequestData(url, data, response.data, response.status));

				return this.handleReturn(response, showMessage, showLoader);
			}.bind(this))
			.catch(function (response) {
				return this.handleReturn(response, showMessage, showLoader);
			}.bind(this));
	}

	mountRequestData(url, request, response, httpStatusCode) {
		return `New request: ${JSON.stringify({
			url,
			request,
			response,
			httpStatusCode
		})}`;
	}
}