import CheckoutRequest from "./checkoutRequest.model";

export default class Cart {
    constructor() {
        this.campaignId = 0;
        this.userId = 0;
        this.paymentMethodId = null;
        this.paymentMethodProviderId = null;
        this.companyId = null;
        this.statusId = 0;
        this.totalValue = 0.0;
        this.creationDate = new Date();
        this.lastUpdateDate = new Date();
        this.checkoutRequest = new CheckoutRequest();
        this.parcelamentId = null;
        this.taxValue = 0;
        this.cartItems = [];
        this.isAnonymous = false;
        this.hasProjectIncetived = false;
    }
}