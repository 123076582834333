<template>
    <nav class="steps" :page-options="pageOptions">
        <template v-for="(stepNumber, index) in steps">
            <router-link
                v-if="stepsValidation['step' + stepNumber]"
                class="done"
                v-bind:key="index"                
                :to="{ name: pages[index], query: query }"
            >
                <faIcon type="fas" name="check" colorFon="white" />
            </router-link>
            <router-link
                v-else-if="isActive(index, stepNumber)"
                class="active"
                v-bind:key="index"                
                :to="{ name: pages[index], query: query }"
            >{{ index + 1 }}</router-link>
            <router-link
                v-else
                v-bind:key="index"
                :to="{ name: pages[index], query: query }"
            >{{ stepNumber }}</router-link>
        </template>
    </nav>
</template>

<script>
import { Routes } from "@/scripts/models/enums/routes.enum.js";
export default {
    props: ["pageOptions", "volunteering"],
    computed: {
        steps() {
            return [1, 2, 3, 4, 5, 6];
        },
    },
    watch: {
        volunteering() {
            if (this.volunteering.id > 0) this.validateSteps();
        },
        'volunteering.volunteeringAttributeOffers'() {
            this.validateSteps();
        }
    },
    data() {
        return {
            pages: [
                Routes.app.VolunteeringStep1,
                Routes.app.VolunteeringStep2,
                Routes.app.VolunteeringStep3,
                Routes.app.VolunteeringGallery,
                Routes.app.VolunteeringStep5,
                Routes.app.VolunteeringStep6
            ],
            stepsValidation: {
                step1: false,
                step2: false,
                step3: false,
                step4: false,
                step5: false,
            },
            query: {}
        };
    },
    created() {
        this.query = this.$route.query;

        if (!this.query.volunteeringId) {
            this.query.volunteeringId = this.volunteering.id;
        }

        this.validateSteps();
    },
    methods: {
        validateSteps() {
            this.stepsValidation.step1 = this.validateStep1();
            this.stepsValidation.step2 = this.validateStep2();
            this.stepsValidation.step3 = this.validateStep3();
            this.stepsValidation.step4 = this.validateStep4();
            this.stepsValidation.step5 = this.validateStep5();
        },
        validateStep1() {
            let fill = true;
            if (this.volunteering.fullName == "") fill = false;
            if (this.volunteering.shortName == "") fill = false;
            if (this.volunteering.tags == "") fill = false;
            if (this.volunteering.causeId == 0) fill = false;
            if (this.volunteering.metaTitle == "") fill = false;
            if (this.volunteering.metaDescription == "") fill = false;
            if (this.volunteering.faviconFile == null || this.volunteering.faviconFile.id == 0)
                fill = false;
            return fill;
        },
        validateStep2() {
            let fill = true;
            if (this.volunteering.volunteeringCoverages && this.volunteering.volunteeringCoverages.length == 0) fill = false;
            if (this.volunteering.volunteeringTestimonies && this.volunteering.volunteeringTestimonies.length == 0) fill = false;
            return fill;
        },
        validateStep3() {
            let fill = true;
            if (this.volunteering.about == "") fill = false;
            if (this.volunteering.beneficiaries == 0) fill = false;
            return fill;
        },
        validateStep4() {
            let hasHero = false;
            let hasImage = false;

            if (this.volunteering.volunteeringFiles) {
                this.volunteering.volunteeringFiles.forEach((file) => {
                    if (file.isHero) hasHero = true;
                    else if (!file.isFavicon) hasImage = true;
                });
                return hasHero && hasImage;
            }

            return false;
        },
        validateStep5(){
            return this.volunteering && this.volunteering.volunteeringAttributeOffers && this.volunteering.volunteeringAttributeOffers.length > 0;
        },
        isActive(index, stepNumber) {
            return index + 1 == this.pageOptions.currentPage && !this.stepsValidation['step' + stepNumber];
        }
    },
};
</script>
