<style scoped>
  pre {
     overflow-x: auto;
     white-space: pre-wrap;
     white-space: -moz-pre-wrap;
     white-space: -pre-wrap;
     white-space: -o-pre-wrap;
     word-wrap: break-word;
  }
</style>
<template>
	<Modal
		v-model="dialog"
		modalTitle="Termos de Uso"
		:cardTitle="true"
		:withClose="true"
		myMaxWidth="640px"
		@input="$emit('close')"
	>
		<v-container fluid pa-0>
			<v-layout pa-0>
				<v-flex list-scroll sm12 style="height:82vh">
					<div class="content-text-block overflow font-sys">
						<pre>
<h3>TERMO DE USO</h3>

<b>1. Quais informações estão presentes neste Termo de Uso?</b>

Neste Termo de Uso, o usuário do site <b>www.abraceumacausa.com.br</b>, doravante referido como <b>Abrace Uma Causa (AUC)</b> encontrará informações sobre: o funcionamento da Abrace Uma Causa e as regras aplicáveis a ela; o arcabouço legal relacionado à <b>Abrace Uma Causa</b>; as responsabilidades do usuário ao utilizar a plataforma; as responsabilidades da <b>Abrace Uma Causa</b> Gestão e Serviços LTDA. Inscrita no CNPJ sob n° 26.246.070/0001-18 ao prover o site; informações para contato, caso exista alguma dúvida ou seja necessário atualizar informações; e o foro responsável por eventuais reclamações caso questões deste Termo de Uso tenham sido violadas.

Além disso, em nossa Política de Privacidade, o usuário da <b>Abrace Uma Causa</b> encontrará informações sobre: qual o tratamento dos dados pessoais realizados, de forma automatizada ou não, e a sua finalidade; os dados pessoais dos usuários necessários para a prestação do serviço; a forma como eles são coletados; se há o compartilhamento de dados com terceiros; e quais as medidas de segurança implementadas para proteger os dados.

<b>2. Aceitação do Termo de Uso e Política de Privacidade</b>

Ao utilizar a <b>Abrace Uma Causa</b>, o usuário confirma que leu e compreendeu os Termos e Políticas aplicáveis à <b>Abrace Uma Causa</b> e concorda em ficar vinculado a eles.

<b>3. Definições</b>

Para melhor compreensão deste documento, neste Termo de Uso e Política de Privacidade, consideram-se:

•	Abrace Uma Causa: Consultoria especializada em captação de recursos para entidades do terceiro setor. Proprietária da plataforma digital, responsável por seu funcionamento e implantação, bem como pelo gerenciamento dos recursos captados via web. É o operador dos dados dos usuários das campanhas.
•	Agentes de tratamento: o controlador e o operador.
•	Autoridade nacional: órgão da administração pública responsável por zelar, implementar e fiscalizar o cumprimento desta Lei em todo o território nacional.
•	Códigos maliciosos: é qualquer programa de computador, ou parte de um programa, construído com a intenção de provocar danos, obter informações não autorizadas ou interromper o funcionamento de sistemas e/ou redes de computadores;
•	Controlador: pessoa natural ou jurídica, de direito público ou privado, a quem competem as decisões referentes ao tratamento de dados pessoais.
•	Dado pessoal: informação relacionada a pessoa natural identificada ou identificável.
•	Encarregado: pessoa indicada pelo controlador e operador para atuar como canal de comunicação entre o controlador, os titulares dos dados e a Autoridade Nacional de Proteção de Dados (ANPD).
•	Gestora da Campanha: Pessoa jurídica que, através de termo de compromisso celebrado junto à AUC, autoriza a divulgação das campanhas de doações e é controlador das informações dos usuários da campanha.
•	Instituição / Fundo / Entidade Apoiada: Organização sem fins lucrativos à qual os recursos captados serão destinados. Possui projeto aprovado e vigente, nos termos das Leis de Incentivos Fiscais ou Causas escolhidas para apoio pela Gestora da Campanha.
•	Internet: o sistema constituído do conjunto de protocolos lógicos, estruturado em escala mundial para uso público e irrestrito, com a finalidade de possibilitar a comunicação de dados entre terminais por meio de diferentes redes;
•	Sítios e aplicativos: sítios e aplicativos por meio dos quais o usuário acessa os serviços e conteúdos disponibilizados; 
•	Terceiro: Pessoa ou entidade que não participa diretamente em um contrato, em um ato jurídico ou em um negócio, ou que, para além das partes envolvidas, pode ter interesse num processo jurídico.
•	Titular: pessoa natural a quem se referem os dados pessoais que são objeto de tratamento.
•	Tratamento: toda operação realizada com dados pessoais, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.
•	Usuário/Doador: Pessoa física que, ao tomar ciência dos projetos apoiados/indicados pela Gestora da Campanha, tem a oportunidade de efetivar doações a projetos aprovados pelas leis de incentivo fiscal. É o titular dos seus dados armazenados na plataforma.
•	Uso compartilhado de dados: comunicação, difusão, transferência internacional, interconexão de dados pessoais ou tratamento compartilhado de bancos de dados pessoais por órgãos e entidades públicos no cumprimento de suas competências legais, ou entre esses e entes privados, reciprocamente, com autorização específica, para uma ou mais modalidades de tratamento permitidas por esses entes públicos, ou entre entes privados.
•	Violação de dados pessoais: é uma violação de segurança que provoque, de modo acidental ou ilícito, a destruição, a perda, a alteração, a divulgação ou o acesso não autorizado a dados pessoais transmitidos, conservados ou sujeitos a qualquer outro tipo de tratamento.

<b>4. Quais são as leis e normativos aplicáveis a Abrace Uma Causa?</b>

•	Lei nº 12.965, de 23 de abril de 2014 Marco Civil da Internet: estabelece princípios, garantias, direitos e deveres para o uso da Internet no Brasil.
•	Lei nº 13.709, de 14 de agosto de 2018: dispõe sobre a proteção de dados pessoais.

<b>5. O que é a Abrace Uma Causa?</b>

A Abrace uma Causa é uma plataforma que facilita doações de pessoas físicas a projetos sociais.

<b>- Funcionalidade da Plataforma</b>

Após celebrado o contrato entre a Gestora da Campanha e <b>Abrace Uma Causa</b>, os usuários poderão acessar o site para realizar suas doações.

Selecionado o projeto / causa que deseja apoiar, cadastra-se com todos os dados necessários à emissão de recibo e vinculação da doação ao CPF. As doações serão efetivadas pelas formas escolhidas / habilitadas pela Gestora da Campanha, que podem ser: i) desconto em folha de pagamento; ii) pagamento de Boleto; iii) cartão de crédito; iv) débito automático em conta corrente.

Para o monitoramento do projeto deve-se acessar a página “projetos” e verificar como anda o sistema interno da Instituição.

<b>- Abatimento Fiscal</b>

As Leis de Incentivo fiscal sobre doações funcionam para que a pessoa (física ou jurídica) escolha direcionar parte de seu imposto de renda para projetos sociais reconhecidos pelo governo.

Na sua declaração de imposto de renda (no modo completo), é possível informar as doações a projetos incentivados realizadas durante o ano-exercício e, com isso, o sistema da Receita Federal do Brasil efetua o abatimento (dedução) ou devolução (restituição) dos valores doados.

<b>IMPORTANTE:</b> a <b>Abrace Uma Causa</b> não garante o abatimento fiscal das doações realizadas, quando as inciativas financiadas permitirem tal mecanismo. É dever do usuário organizar os documentos necessários à declaração do Imposto de Renda e, quando possível, dedução/restituição dos valores doados.

<b>- Campanhas Não Incentivadas</b>

A Gestora da Campanha poderá ter campanhas de doações não incentivadas, ou seja, essas doações não dão direito ao Abatimento Fiscal. É de responsabilidade da Pessoa Física se atentar a qual tipo de campanha está fazendo sua doação.

<b>- Forma de Doação</b>

As doações na plataforma da <b>Abrace Uma Causa</b> ocorrem por meio de pessoa física que escolhe um projeto e define o valor de sua doação. Essa quantia deverá ser paga pela forma escolhida.

Declaro ciência que para as transações de CARTÃO DE CRÉDITO, considerando principalmente sua finalidade social, <b>não estará passível para pedido de “CHARGEBACK”</b> junto a operadora / emissora do cartão de crédito utilizado. Declaro ainda ser responsável pelo cartão de crédito utilizado e estar ciente de que essa operação, pela sua natureza não está passível de estorno ou devolução após o seu processamento, salvo em ocorrências fraudulentas, devidamente comprovadas.

<b>- Redirecionamento de Recursos</b>

Caso a Instituição e/ou projeto escolhido pelo doador não possa receber as doações, o contribuinte concorda que sua doação seja destinada um projeto similar.

<b>- Propriedade da Abrace Uma Causa e seus Conteúdos</b>

O logotipo e qualquer outro material utilizado nesse site pertence à <b>Abrace Uma Causa</b>, salvo o material produzido pelos institutos apoiados. Não é permitido, portanto, que terceiros utilizem imagens e conteúdo sem autorização prévia da <b>Abrace Uma Causa</b>.

<b>6. Quais são os direitos do usuário do serviço?</b>

O usuário da <b>Abrace Uma Causa</b> possui os seguintes direitos, conferidos pela Lei de Proteção de Dados Pessoais:

•	Direito de confirmação e acesso (Art. 18, I e II): é o direito do usuário de obter da plataforma a confirmação de que os dados pessoais que lhe digam respeito são ou não objeto de tratamento e, se for esse o caso, o direito de acessar os seus dados pessoais.
•	Direito de retificação (Art. 18, III): é o direito de solicitar a correção de dados incompletos, inexatos ou desatualizados.
•	Direito à limitação do tratamento dos dados (Art. 18, IV): é o direito do usuário de limitar o tratamento de seus dados pessoais, podendo exigir a eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na Lei Geral de Proteção de Dados, quando aplicável e respeitado o cumprimento de obrigação legal e regulatória pela <b>Abrace Uma Causa</b>, bem como os segredos comercial e industrial.
•	Direito de oposição (Art. 18, § 2º): é o direito do usuário de, a qualquer momento, se opor ao tratamento de dados por motivos relacionados com a sua situação particular, com fundamento em uma das hipóteses de dispensa de consentimento ou em caso de descumprimento ao disposto na Lei Geral de Proteção de Dados.
•	Direito de portabilidade dos dados (Art. 18, V): é o direito do usuário de realizar a portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial.
•	Direito de não ser submetido a decisões automatizadas (Art. 20, LGPD): o titular dos dados tem direito a solicitar a revisão de decisões tomadas unicamente com base em tratamento automatizado de dados pessoais que afetem seus interesses, incluídas as decisões destinadas a definir o seu perfil pessoal, profissional, de consumo e de crédito ou os aspectos de sua personalidade.
•	Direito do respeito à intimidade (Constituição Federal, Art. 5º, X) - O tratamento das informações pessoais deve ser feito de forma transparente e com respeito à intimidade, vida privada, honra e imagem das pessoas, bem como às liberdades e garantias individuais.

<b>7. Quais são as obrigações dos usuários que utilizam a plataforma?</b>

O site da Abrace Uma Causa é uma plataforma direcionada a pessoas em plena capacidade. Menores de idade não gozam da possibilidade de uso.

Ao acessar a plataforma o indivíduo deve manifestar seu consentimento por meio do aceito ao “termo de anuência” presente na confirmação de doação

O usuário se responsabiliza pela precisão e veracidade dos dados informados e reconhece que a inconsistência destes poderá implicar a impossibilidade de se utilizar a <b>Abrace Uma Causa</b>. Durante a utilização da plataforma, a fim de resguardar e de proteger os direitos de terceiros, o usuário se compromete a fornecer somente seus dados pessoais, e não os de terceiros e mantê-los permanentemente atualizados.

O login e senha só poderão ser utilizados pelo usuário cadastrado. Ele se compromete em manter o sigilo da senha, que é pessoal e intransferível, não sendo possível, em qualquer hipótese, a alegação de uso indevido, após o ato de compartilhamento.

O usuário da plataforma é responsável pela atualização das suas informações pessoais e consequências na omissão ou erros nas informações pessoais cadastradas.

O Usuário é responsável pela reparação de todos e quaisquer danos, diretos ou indiretos (inclusive decorrentes de violação de quaisquer direitos de outros usuários, de terceiros, inclusive direitos de propriedade intelectual, de sigilo e de personalidade), que sejam causados à <b>Abrace Uma Causa</b>, a qualquer outro Usuário, ou, ainda, a qualquer terceiro, inclusive em virtude do descumprimento do disposto nestes Termos de Uso e Política de Privacidade ou de qualquer ato praticado a partir de seu acesso à Internet e ao sítio www.abraceumacausa.com.br.

A Abrace Uma Causa não poderá ser responsabilizada pelos seguintes fatos:
a. Equipamento infectado ou invadido por atacantes;
b. Equipamento avariado no momento do consumo de serviços;
c. Proteção do computador;
d. Proteção das informações baseadas nos computadores dos usuários;
e. Abuso de uso dos computadores dos usuários;
f. Monitoração clandestina do computador dos usuários;
g. Vulnerabilidades ou instabilidades existentes nos sistemas dos usuários;
h. Perímetro inseguro.

Em nenhuma hipótese, a <b>Abrace Uma Causa</b> será responsável pela instalação no equipamento do Usuário ou de terceiros, de códigos maliciosos (vírus, trojans, malware, worm, bot, backdoor, spyware, rootkit, ou de quaisquer outros que venham a ser criados), em decorrência da navegação na Internet pelo Usuário.

<b>8. Quais são as responsabilidades da Abrace Uma Causa com meus dados?</b>

A <b>Abrace Uma Causa</b> se compromete a cumprir todas as legislações inerentes ao uso correto dos dados pessoais do cidadão de forma a preservar a privacidade dos dados utilizados na plataforma, bem como a garantir todos os direitos e garantias legais dos titulares dos dados. Ela também se obriga a promover, independentemente de requerimentos, a divulgação em local de fácil acesso, no âmbito de suas competências, de informações de interesse coletivo ou geral por eles produzidas ou custodiadas, bem como implementar controles de segurança para proteção dos dados pessoais dos titulares.

A <b>Abrace Uma Causa</b> poderá, quanto às ordens judiciais de pedido das informações, compartilhar informações necessárias para investigações ou tomar medidas relacionadas a atividades ilegais, suspeitas de fraude ou ameaças potenciais contra pessoas, bens ou sistemas que sustentam a plataforma ou de outra forma necessária para cumprir com nossas obrigações legais. 

Caso ocorra, os titulares dos dados serão notificados, salvo quando o processo estiver em segredo de justiça.

A <b>Abrace Uma Causa</b> toma todas as providências para total proteção de seus dados perante terceiros.

A Abrace Uma Causa compromete-se em não fornecer informações ou dados pessoais para terceiros, salvo os necessários para a emissão do recibo por parte das Entidades e Fundos.

Os dados obtidos e coletados serão armazenados enquanto for Usuário da <b>Abrace Uma Causa</b>. Esse período de armazenamento poderá ser revisto em alinhamento a qualquer nova disposição legal sobre prazo de retenção. O titular pode requisitar informações sobre seus dados pessoais a qualquer momento.

<b>9. Este Termo de Uso pode ser alterado?</b>

A presente versão deste Termo de Uso foi atualizada pela última vez em: 11 de maio de 2021. A <b>Abrace Uma Causa</b> se reserva o direito de modificar este documento a qualquer momento, especialmente para adaptá-lo às evoluções da <b>Abrace Uma Causa</b>, seja pela disponibilização de novas funcionalidades, seja pela supressão ou modificação daquelas já existentes.

Qualquer alteração e/ou atualização deste Termos de Uso passará a vigorar a partir da data de sua publicação no sítio da <b>Abrace Uma Causa</b> e deverá ser integralmente observada pelos Usuários.

<b>10. Qual o contato pelo qual o usuário da Abrace Uma Causa pode tirar suas dúvidas?</b>

O e-mail dpo@abraceumacausa.com.br é disponibilizado para que os titulares dos dados pessoais possam demandar as solicitações previstas pelo art. 18º da LGPD e para sanar quaisquer dúvidas sobre este o serviço ou sobre este Termo de Uso.

<b>11. Qual o foro aplicável à Abrace Uma Causa?</b>

Este Termo será regido pela legislação da República Federativa do Brasil. Qualquer reclamação ou controvérsia com base neste Termo será dirimida exclusivamente pela comarca de São Paulo-SP.
						</pre>
					</div>
				</v-flex>
			</v-layout>
		</v-container>
	</Modal>
</template>
<script type="plain/text">
import Modal from "@/components/Modal.vue";

export default {
	props: ["value"],
	components: {
		Modal
	},
	data() {
		return {
			dialog: false
		};
	},
	watch: {
		value() {
			this.dialog = this.value;
		}
	},
	methods: {
		toggleDialog() {
			this.dialog = !this.dialog;
			this.$emit("input", this.dialog);
		}
	}
};
</script>
