import CartStorage from "@/scripts/services/cartStorage.service";;

const CartModule = {
    state: {
        cartItems: [],
        cart: Object,
    },
    mutations: {
        ADD_TO_CART: (state, payload) => {
            CartStorage.addToCart(payload.item, payload.isLogged);
            state.cart = CartStorage.getCart();
        },
        REMOVE_TO_CART: (state, payload) => {
            CartStorage.removeToCart(payload);
            state.cart = CartStorage.getCart();
        },
        UPDATE_CART: (state, payload) => {
            CartStorage.clearCart();
            CartStorage.setCart(payload.item);
            if (payload.item.cartItems) {
                for (let i = 0; i < payload.item.cartItems.length; i++) {
                    CartStorage.addToCart(payload.item.cartItems[i], false);
                }
            }
            state.cart = CartStorage.getCart();
        },
        CLEAR_CART: (state) => {
            CartStorage.clearCart();
            state.cart = CartStorage.getCart();
        },
        REFRESH_CART: (state) => {
            state.cart = CartStorage.getCart();
        },
    },
    getters: {
        cartLocal: state => {         
            // if (!state.cart.campaign) {
            //     state.cart.campaign = {
            //         campaignPaymentMethodList: null
            //     }
            // }
            return state.cart
        },
        cartItems: state => {
            state.cart = CartStorage.getCart();
            return state.cart.cartItems;
        },
        alreadyExistsInCart: (state) => (item) => {
            if(state.cart && state.cart.cartItems)
                return state.cart.cartItems.filter(e => item.donationProduct ? e.donationProduct && e.donationProduct.id == item.donationProduct.id : item.project.id == e.project.id).length != 0;
            return false;
        }
    },
    actions: {
        addToCart(state, value) {
            var payload = { item: value, isLogged: state.getters.logged };
            state.commit('ADD_TO_CART', payload);
        },
        removeToCart(state, value) {
            var payload = { item: value, isLogged: state.getters.logged };
            state.commit('REMOVE_TO_CART', payload);
        },
        updateCart(state, value) {
            var payload = { item: value, isLogged: state.getters.logged };
            state.commit('UPDATE_CART', payload);
        },
        clearCart(state) {
            state.commit('CLEAR_CART');
        },
        refreshCart(state) {
            state.commit('REFRESH_CART');
        },
    }
}

export default CartModule;