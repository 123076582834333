<template>
  <div class="text-xs-left input-comp">
    <v-flex d-flex justify-space-between  v-if="textLabel">
      <label v-if="textLabel" class="d-flex">{{ textLabel }}{{ required ? ' *' : '' }}</label>
      <label v-if="labelCharLimit > 0" class="d-flex justify-end"
        >{{ labelCharLimit - value.length }}/{{ labelCharLimit }}</label
      >
    </v-flex>
    <div class="inside-input">
      <input
        v-if="mask == null"
        v-focus="focusOnCreate"
        ref="input"
        :type="type"
        :value="value"
        :maxlength="labelCharLimit"
        @blur="$emit('blur', $event.target.value)"
        @input="$emit('input', $event.target.value)"
        @focus="$emit('focus', helpBoxText)"
        @keydown="$emit('keydown', $event.target.value)"
        @keydown.enter="$emit('pressenter', $event.target.value)"
        :disabled="disabled"
        :placeHolder="placeHolder"
        :autocomplete="autocomplete"
        :required="required"
        :id="id"
        :style="'width: ' + width"
      />
      <the-mask
        v-else
        v-focus="focusOnCreate"
        ref="input"
        :type="type"
        :value="value"
        :maxlength="labelCharLimit"
        @blur.native="$emit('blur', $event.target.value)"
        @input="$emit('input', $event)"
        @focus="$emit('focus', helpBoxText)"
        @keydown="$emit('keydown', $event.target.value)"
        :disabled="disabled"
        :placeHolder="placeHolder"
        :mask="mask"
        :masked="masked"
        :autocomplete="autocomplete"
        :style="'width: ' + width"
        :required="required"
        :id="id"
      ></the-mask>
      <template class="loader" v-if="showLoader">
        <svg
          class="loader-input"
          version="1.1"
          id="loader-1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="40px"
          height="40px"
          viewBox="0 0 40 40"
          enable-background="new 0 0 40 40"
          xml:space="preserve"
        >
          <path
            opacity="0.7"
            fill="#C3C6D5"
            d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
    s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
    c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
          />
          <path
            fill="#02ACB4"
            d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
    C22.32,8.481,24.301,9.057,26.013,10.047z"
          >
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="rotate"
              from="0 20 20"
              to="360 20 20"
              dur="0.5s"
              repeatCount="indefinite"
            />
          </path>
        </svg>
      </template>
      <template v-if="type == 'search' && !showLoader">
        <i class="fal fa-search"></i>
      </template>
      <template v-if="infoVisible">
        <i class="fal fa-info-circle"></i>
        <div>
          <h5>{{ titleInfo }}</h5>
          <p>{{ subTitleInfo }}</p>
        </div>
      </template>
    </div>
    <v-flex
      justify-space-between
      d-flex
      v-if="helpLabel || invalidInputMessage"
    >
      <label
        v-if="showValidationMessage"
        class="d-flex justify-end msg-validation"
        :key="showValidationMessage"
        >{{ invalidInputMessage }}</label
      >
      <label
        v-if="helpLabel && !showValidationMessage"
        class="d-flex justify-end labelHelp text-color"
        >{{ helpLabel }}</label
      >
    </v-flex>
  </div>
</template>
<script>
import ValidatableInput from "@/components/base/ValidatableInput.vue";
export default {
  extends: ValidatableInput,
  props: {
    type: "",
    helpBoxText: "",
    autocomplete: "",
    placeHolder: "",
    labelCharLimit: 0,
    focusOnCreate: false,
    infoVisible: false,
    titleInfo: null,
    subTitleInfo: null,
    mask: null,
    masked: false,
    showLoader: false,
    width: null,
    required: Boolean,
    id: String
  },
  directives: {
    focus: {
      inserted: function (el, binding) {
        if (binding.value) el.focus();
      },
    },
  },
};
</script>
