<template>
  <div>
    <div>
      <label v-if="textLabel" class="d-flex">{{textLabel}}</label>
      <label
        v-if="labelAreaCharLimit > 0"
        class="d-flex"
      >{{labelAreaCharLimit - value.length}}/{{labelAreaCharLimit}}</label>
    </div>
    <textarea
      ref="input"
      :value="value"
      :placeHolder="placeHolder"
      :rows="rows"
      :maxlength="labelAreaCharLimit"
      @input="$emit('input', $event.target.value)"
      @focus="$emit('focus', helpBoxText)"
    ></textarea>
    <v-flex justify-space-between d-flex>
      <label
        v-if="showValidationMessage"
        class="d-flex justify-end msg-validation"
        :key="showValidationMessage"
      >{{invalidInputMessage}}</label>
      <label
        v-if="helpLabel && !showValidationMessage"
        class="d-flex justify-end labelHelp"
      >{{helpLabel}}</label>
    </v-flex>
  </div>
</template>

<script type="plain/text">
import ValidatableInput from "@/components/base/ValidatableInput.vue";
export default {
  extends: ValidatableInput,
  props: {
    placeHolder: "",
    labelAreaCharLimit: 0,
    helpBoxText: "",
    rows: null
  }
};
</script>

<style lang="stylus" scoped>
.text-xs-right {
  width: 100%;

  .double-label-area {
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
      &:first-child {
        color: $text-color;
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
}

textarea {
  border-radius: 3px;
  border: 1px solid #C3C6D5;
  padding: 14px 16px;
  outline: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
  color: #4C4D4F;
  width: 100%;
  margin-bottom: 15px;

  &:focus {
    border-color: #02ACB4;
    box-shadow: 0 0 0 0.2rem rgba(2, 172, 180, 0.25);
  }
}

&::-webkit-input-placeholder {
  color: #C3C6D5;
}

&:-moz-placeholder {
  color: #C3C6D5;
}

&::-moz-placeholder {
  color: #C3C6D5;
}

&:-ms-input-placeholder {
  color: #C3C6D5;
}
</style>