import Cart from "@/scripts/models/cart.model";
import CheckoutRequest from "@/scripts/models/checkoutRequest.model";
import CreditCardCartDetails from "@/scripts/models/creditCardCartDetails.model";
import CartService from "@/scripts/services/cart.service";
import store from "@/store";

const TICKETCART = 'ticket-cart'
const CART = 'cart'
const cartService = new CartService(null);

const CartStorage = {
    getTicketCart() {
        let cart = localStorage.getItem(TICKETCART);
        return JSON.parse(cart);
    },

    setTicketCart(cart) {
        localStorage.setItem(TICKETCART, JSON.stringify(cart));
    },

    removeTicketCart() {
        localStorage.removeItem(TICKETCART);
    },

    getCart() {
        let cart = localStorage.getItem(CART);
        if (cart){
            let cartJson = JSON.parse(cart);
            let today = new Date();
            let lastUpdateDate = new Date(cartJson.lastUpdateDate);
            let difference= Math.abs(today - lastUpdateDate);
            let totalDays = difference/(1000 * 3600 * 24);

            if (totalDays < 7) {
                return cartJson;
            }

            // if (cartJson.cartItems) {
            //     cartJson.cartItems.forEach(item => {
            //         cartService.removeCartItem(item.id, false, false);
            //     });
            // }
        } 

        this.clearCart();
        cart = new Cart();
        cart.checkoutRequest.creditCardInfo.billingAddress.country = "BRA";
        return cart;
    },

    setCart(cart) {
        if (!cart.checkoutRequest) {
            cart.checkoutRequest = new CheckoutRequest();
            cart.checkoutRequest.creditCardInfo.billingAddress.country = "BRA";
        }
        if (!cart.creditCardCartDetails) {
            cart.creditCardCartDetails = new CreditCardCartDetails();
        }
        let code = localStorage.getItem("indication_code");  
        cart.checkoutRequest.indicationCode = code != null ? code : null; 
        localStorage.setItem(CART, JSON.stringify(cart));
    },

    addToCart(item, isLogged) {        
        var cart = this.getCart();
        item.itemValue = 0;
        if (cart.cartItems.filter(e => item.donationProduct ? e.donationProduct && e.donationProduct.id == item.donationProduct.id : item.project.id == e.project.id).length == 0) {
            if (isLogged) {
                cartService.addCartItem(item.project.id, item.donationProduct ? item.donationProduct.id : null, false, false).then(data => {
                    var payload = { item: data, isLogged: false };
                    store.commit('UPDATE_CART', payload);
                }, error => {
                    if (error && error.errorMessage) {
                        this.rollbackAddItem(item, error.errorMessage);
                    }
                });
            }
            cart.cartItems.push(item);
            localStorage.setItem(CART, JSON.stringify(cart));
        }
    },

    removeToCart(payload) {
        var cart = this.getCart();
        if (payload.isLogged) {
            var projectId = payload.item.project.id;
            var donationProductId = payload.item.donationProduct ? payload.item.donationProduct.id : null;
            cartService.removeCartItemByProjectAndDonationProduct(projectId, donationProductId, false, false).then({}, data => {
                if (data && data.errorMessage) {
                    this.rollbackRemoveItem(payload.item, data.errorMessage);
                }
            });

        }
        cart.cartItems = cart.cartItems.filter(e => e.donationProduct && payload.item.donationProduct ? e.donationProduct.id != payload.item.donationProduct.id : e.project.id != payload.item.project.id);
        localStorage.setItem(CART, JSON.stringify(cart));
    },

    clearCart() {
        localStorage.removeItem(CART);
    },

    rollbackAddItem(item, message) {
        store.commit(
            "SET_SNACKBAR_MESSAGE",
            "Não foi possível adicionar o item ao carrinho. " + message
        );
        var payload = { item: item, isLogged: false };
        this.removeToCart(payload);
        store.commit('REFRESH_CART');
    },

    rollbackRemoveItem(item, message) {
        store.commit(
            "SET_SNACKBAR_MESSAGE",
            "Não foi possível remover o item do carrinho. " + message
        );
        this.addToCart(item, false);
        store.commit('REFRESH_CART');
    }
}

export default CartStorage;