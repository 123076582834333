<style scoped>
  pre {
     overflow-x: auto;
     white-space: pre-wrap;
     white-space: -moz-pre-wrap;
     white-space: -pre-wrap;
     white-space: -o-pre-wrap;
     word-wrap: break-word;
  }
</style>
<template>
  <Modal
    modalTitle="Política de Privacidade"
    v-model="dialog"
    :cardTitle="true"
    :withClose="true"
    myMaxWidth="640px"
    @input="$emit('close')"
  >
    <v-container fluid pa-0>
      <v-layout pa-0>
        <v-flex list-scroll sm12 style="height:85vh">
          <div class="content-text-block overflow font-sys">
              <h3>POLÍTICA DE PRIVACIDADE DA ABRACE UMA CAUSA</h3>
              <pre>
Esta Política de Privacidade foi elaborada para atendimento dos dispositivos previstos na Lei Federal nº 13.709/2018 - Lei Geral de Proteção de Dados Pessoais (“LGPD”) o Marco Civil da Internet (Lei 12.965 /14) e o Regulamento da UE n. 2016/6790, sem prejuízo de observância das demais legislações aplicáveis à privacidade e proteção de dados pessoais tratados durante sua experiência conosco.

<b> I.	Objetivo.</b>A Política de Privacidade da Abrace uma Causa (“Política”) foi criada para demonstrar o nosso compromisso com a privacidade e a segurança de suas informações que podemos vir a coletar em nosso site Abrace uma Causa, e outros sites que possuímos e operamos.

<b>II.	Quem Somos.</b> ABRACE UMA CAUSA GESTÃO E SERVIÇOS LTDA., sociedade empresária limitada, inscrita no CNPJ sob o nº 26.246.070/0001-18, com sede na Cidade de São Paulo, Estado de São Paulo, na Av. Brigadeiro Faria Lima, 1755, Box 46, Jardim Paulistano, CEP 01452-001 (“AUC”; “Nós“). De acordo com a LGPD, a partir do momento que você nos fornece alguns de seus dados pessoais, nos tornamos responsáveis pela utilização, armazenamento e proteção dos mesmos, na qualidade de controlador de suas informações conforme a definição legal. 

<b>III.	Dados pessoais coletados.</b> Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço. Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento. Também informamos por que estamos coletando e como será usado. Durante seu relacionamento conosco, podemos coletar tipos diferentes de dados pessoais de forma automática, para fins de conferência, monitoramento e controle ou então fornecidas diretamente por você, tais como no momento de criação de conta na plataforma. Nesse sentido, listamos abaixo os dados pessoais que podemos coletar de acordo com cada situação:

- Informações que você digita em nossos formulários, tais como nome completo, endereço de e-mail, CPF, endereço e telefone.

- Informações acerca de suas atividades na plataforma, tais como recibo, informe, consulta às doações feitas, emissão de segunda via de boleto.

- Informações obtidas automaticamente através de sua navegação pelo site, tais como seu endereço de IP, eventuais endereços de sites visitados e os caminhos percorridos através de nossa página na web. 

A AUC não armazena dados de cartão de crédito/débito, os quais são processados via gateway diretamente, que possuem certificação PCI-DSS (Payment Card Industry – Data Security Standard).

<b>IV.	Finalidades do tratamento de dados pessoais.</b> Visando a proteção de nossa empresa e dos nossos clientes, a AUC não vende, aluga ou empresta os dados pessoais constantes de nosso Banco de Dados para terceiros. 

Nos comprometemos em utilizar seus dados pessoais exclusivamente para as seguintes finalidades:

- Criar de conta em Nossa plataforma;

- Identificar o doador;

- Permitir a realização de doações através da plataforma;

- Prestar de contas acerca das doações;

- Garantir envio correto de informações aos órgãos competentes no caso de doações via incentivos fiscais;

- Emitir recibos de doações.

- Facilitar o envio de agradecimentos, contrapartidas e documentos quando pertinente.

<b>V.	Compartilhamento de dados pessoais.</b> A AUC utiliza-se, para a operacionalização de seus serviços, da parceria com diversas empresas localizadas Brasil e no exterior. Deste modo, a AUC poderá compartilhar as suas informações pessoais, nas hipóteses abaixo expostas:

- Com as empresas gestoras das campanhas de doação com a finalidade de garantir a concretização das doações;

- Com repartições públicas e/ou Fundo e/ou Entidade Apoiada com a finalidade de tão somente informar a titularidade da doação realizada;

- Com autoridades, entidades governamentais ou outros terceiros institucionais, para a proteção dos interesses da AUC em qualquer tipo de conflito, incluindo ações judiciais e processos administrativos;

- No caso de transações e alterações societárias envolvendo a AUC, em que a transferência das informações será necessária para a continuidade dos serviços;

- Mediante ordem judicial ou pelo requerimento de autoridades administrativas que detenham competência legal para a sua requisição.

- Adicionalmente, é possível que algumas das transferências acima ocorram fora do território brasileiro. Ocasião em que a AUC se compromete a fazê-lo somente para países que proporcionem um grau de proteção aos seus dados pessoais ao previsto na legislação aplicável, ou mediante a adoção de garantias e salvaguardas, tais como cláusulas contratuais específicas, bem como mediante a prévia coleta do seu consentimento específico quando aplicável.

Fora as hipóteses acima previstas, a AUC e a gestora da campanha não compartilham informações de identificação pessoal publicamente ou com terceiros, exceto quando exigido por lei.

<b>VI.	Dados pessoais seguros.</b> O armazenamento dos seus dados pessoais é feito na nuvem com utilização de criptografia desde a transmissão dos dados do aparelho eletrônico do usuário até a gravação e manutenção no servidor. Qualquer informação fornecida pelos usuários será coletada e guardada de acordo com os mais rígidos padrões de segurança. Para tanto, a AUC adota diversas precauções, em observância às diretrizes sobre padrões de segurança estabelecidas nas legislações aplicáveis, em especial a LGPD. O acesso às informações coletadas é restrito aos colaboradores e às pessoas autorizadas. Aqueles que se utilizarem indevidamente dessas informações, em violação desta Política de Privacidade, estarão sujeitos a sanções disciplinares e legais cabíveis. De qualquer forma, na remota hipótese de incidência de episódios desta natureza, a AUC garante o pleno esforço para remediar as consequências do evento.

<b>VII.	Retenção e exclusão dos dados pessoais.</b> As informações dos usuários são armazenadas pela AUC, operadora dos dados, seguindo todos os protocolos de criptografia em trânsito e em banco de dados, e são controlados pela empresa gestora da campanha, a qual poderá enviar informações referentes a campanhas, pesquisa de satisfação e divulgar novas inciativas correlacionadas com o tema da mesma.

Enquanto seus dados pessoais estiverem sob Nossa gestão, garantimos sua proteção a partir do momento que você os fornece para nós, seja para viabilizar sua operação em nossa comunidade, ou até para receber comunicados, convites, informativos, bem como para melhorar sua experiência de navegação em nosso site. Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado. Quando armazenamos dados, protegemos dentro de meios comercialmente aceitáveis para evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizados. Caso opte em algum momento pela exclusão de seus dados pessoais do nosso banco de armazenamento, poderemos reter algumas informações pessoais por um período adicional para fins de cumprimento de obrigações legais ou regulatórias, para exercício regular de direito da AUC, bem como auditorias. A retenção de seus dados pessoais será feita durante o prazo necessário, sempre respeitando os prazos estabelecidos na legislação aplicável.

<b>VIII.	Direitos como titular de dados pessoais.</b> A AUC respeita e garante aos usuários, a possibilidade de exercerem seus direitos previstos na LGPD, podendo apresentar solicitações relacionados aos seus dados pessoais, tais como:

- Confirmação da existência de tratamento;

- Acesso aos dados pessoais;

- Correção de dados incompletos, inexatos ou desatualizados;

- Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com a LGPD;

- Portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa pelo usuário;

- Eliminação dos dados tratados com consentimento do usuário;

- Obtenção de informações sobre as entidades com as quais compartilhou os seus dados;

- Informação sobre a possibilidade de o usuário não fornecer o consentimento, bem como de ser informado sobre as consequências em caso de negativa;

- Revogação do consentimento.

<b>IX.	Exercendo os seus direitos de titular dos dados.</b> Os seus direitos como titular de dados pessoais poderão ser exercidos diretamente por você, a partir da gestão de informações de cadastro, enquanto outra parte dependerá do envio de requisição por meio de solicitação para o e- mail: dpo@abraceumacausa.com.br, para posterior avaliação e adoção de das providências necessárias. A AUC empreenderá todos os esforços razoáveis para atender as requisições feitas por usuários no menor espaço de tempo possível. 

<b>X.	Alterações da Política de Privacidade.</b> A AUC está sempre trabalhando para aprimorar a experiência e segurança de nossos usuários. Por isso, nossas práticas de tratamento de dados pessoais poderão vir a sofrer alterações futuras de modo a refletir tais aprimoramentos. Sempre que alguma condição relevante desta Política de Privacidade for alterada, tais alterações serão válidas, eficazes e vinculantes após a nova versão ser publicada em nosso site. Quando realizarmos alterações relevantes na presente Política, você será devidamente notificado, conforme as circunstâncias, por exemplo, exibindo um aviso na página principal de https://abraceumacausa.com.br ou enviando-lhe um e-mail para contatos cadastrados em nossos serviços.

<b>XI.	Contato.</b> Se você tiver dúvidas ou preocupações em relação à sua privacidade ou ao tratamento de seus dados pessoais pela EI, entre em contato conosco através do e-mail dpo@abraceumacausa.com.br

<b>XII.	Disposições Finais.</b>

A Abrace uma Causa, a seu exclusivo critério, poderá modificar os termos e condições constantes no presente Aviso, incluindo, mas não se limitando, às hipóteses decorrentes do cumprimento de legislação, às alterações nos produtos e serviços, ou pela implantação de novas ferramentas tecnológicas. Para maior segurança e dentro do princípio da transparência, eventuais atualizações deste Aviso de Privacidade serão disponibilizadas para conhecimento dos titulares no site da Abrace uma Causa (<a href="https://abraceumacausa.com.br" target="blank">https://abraceumacausa</a>). 

A Abrace uma Causa não se responsabiliza por problemas decorrentes de demora, interrupção ou bloqueio nas transmissões dos dados enviados no seu endereço eletrônico.

<h3>POLÍTICA DE COOKIES ABRACE UMA CAUSA</h3>
<b>O QUE SÃO COOKIES?</b>
Cookies são arquivos de texto baixados em seu dispositivo quando você visita um site. São úteis por permitirem que um site reconheça o dispositivo do usuário. Você encontra mais informações sobre cookies em: <a href="https://www.allaboutcookies.org" target="blank">www.allaboutcookies.org</a> ou <a href="https://www.youronlinechoices.eu" target="blank">www.youronlinechoices.eu</a>

O termo cookie na verdade descreve uma série de tecnologias, incluindo:

• pixel tags (imagens gráficas transparentes colocadas em uma página da Web ou em um e-mail para indicar que foram visualizados),
• identificadores de dispositivo móvel, 
• armazenamento na Web usado em softwares do computador ou
• em dispositivos móveis.

Vamos usar o termo cookie ao longo desta política para abranger todas essas tecnologias, mas passaremos a você todos os detalhes sobre elas, assim você faz escolhas conscientes sobre as configurações de cookies.

Os cookies têm várias finalidades, como permitir a navegação eficiente entre páginas, lembrar preferências e, em geral, melhorar a experiência do usuário. Eles também podem ajudar a garantir que os anúncios que você vê online sejam mais relevantes para você e seus interesses.

Como é prática comum em quase todos os sites profissionais, este site usa cookies, que são pequenos arquivos baixados no seu computador, para melhorar sua experiência. Esta página descreve quais informações eles coletam, como as usamos e por que às vezes precisamos armazenar esses cookies. Também compartilharemos como você pode impedir que esses cookies sejam armazenados, no entanto, isso pode fazer o downgrade ou 'quebrar' certos elementos da funcionalidade do site.

Há algumas categorias diferentes de cookies, incluindo:

<b>Cookies de sessão e persistentes</b>
• Cookies de sessão - são cookies que expiram depois que você fecha seu navegador da Web; e
• Cookies persistentes - são cookies que devem permanecer no seu dispositivo por um período definido ou até você excluí-los.

<b>Cookies proprietários e de terceiros</b>
• Cookies proprietários - são cookies definidos pelo site que você está acessando no momento, seja por nós, ou por um terceiro em nosso nome;
• Cookies de terceiros - são cookies definidos por uma parte diferente da do site que você está acessando. Se você visitar o site da Abrace uma Causa e outra parte definir um cookie por meio desse site, será cookie de terceiros.

<b>COMO USAMOS OS COOKIES?</b>
Utilizamos cookies por vários motivos, detalhados abaixo. Infelizmente, na maioria dos casos, não existem opções padrão do setor para desativar os cookies sem desativar completamente a funcionalidade e os recursos que eles adicionam a este site. É recomendável que você deixe todos os cookies se não tiver certeza se precisa ou não deles, caso sejam usados para fornecer um serviço que você usa.

<b>COOKIES DO NAVEGADOR</b>
Você pode retirar ou alterar o seu consentimento sobre o uso de cookies a qualquer momento. Se você não quiser mais receber cookies, use as configurações do navegador da Web para aceitar, recusar e excluir cookies. Para fazer isso, siga as instruções de seu navegador (geralmente dentro das configurações de "Ajuda", "Ferramentas" ou "Editar").
Se você configurar o navegador para recusar cookies, correrá o risco de não conseguir usar todos os recursos do site da Abrace uma Causa. Para obter mais informações, acesse <a href="https://www.allaboutcookies.org" target="blank">www.allaboutcookies.org</a>.

<b>IDENTIFICADORES DE DISPOSITIVOS MÓVEIS</b>
Em seu dispositivo móvel, o sistema operacional pode disponibilizar mais opções para cancelar o interesse com base em anúncios ou, então, redefinir os identificadores de seu celular. Por exemplo, você pode usar a opção "Limitar rastreamento de anúncios" (somente em dispositivos iOS) ou a configuração "Cancelar anúncios com base em interesses" (no Android) que permite limitar o uso de informações sobre o uso de aplicativos para fins de fornecimento de anúncios direcionados a seus interesses.

<b>COOKIES QUE DEFINIMOS</b>
<b>•	Cookies relacionados à conta.</b> Se você criar uma conta conosco, usaremos cookies para o gerenciamento do processo de inscrição e administração geral. Esses cookies geralmente serão excluídos quando você sair do sistema, porém, em alguns casos, eles poderão permanecer posteriormente para lembrar as preferências do seu site ao sair.

<b>•	Cookies relacionados ao login.</b> Após efetuado o login, guarda-se as informações na máquina do usuário para que as mesmas sejam enviadas a cada nova requisição da página. Assim que o usuário fechar a página os cookies são apagados automaticamente.
<b>•	Cookies relacionados a boletins por e-mail.</b> Este site oferece serviços de assinatura de boletim informativo ou e-mail e os cookies podem ser usados para lembrar se você já está registrado e se deve mostrar determinadas notificações válidas apenas para usuários inscritos / não inscritos.

<b>•	Pedidos processando cookies relacionados.</b> Este site oferece facilidades de comércio eletrônico ou pagamento e alguns cookies são essenciais para garantir que seu pedido seja lembrado entre as páginas, para que possamos processá-lo adequadamente.

<b>•	Cookies relacionados a pesquisas.</b> Periodicamente, oferecemos pesquisas e questionários para fornecer informações interessantes, ferramentas úteis ou para entender nossa base de usuários com mais precisão. Essas pesquisas podem usar cookies para lembrar quem já participou numa pesquisa ou para fornecer resultados precisos após a alteração das páginas.

<b>•	Cookies relacionados a formulários.</b> Quando você envia dados por meio de um formulário como os encontrados nas páginas de contato ou nos formulários de comentários, os cookies podem ser configurados para lembrar os detalhes do usuário para correspondência futura.

<b>•	Cookies de preferências do site.</b> Para proporcionar uma ótima experiência neste site, fornecemos a funcionalidade para definir suas preferências de como esse site é executado quando você o usa. Para lembrar suas preferências, precisamos definir cookies para que essas informações possam ser chamadas sempre que você interagir com uma página for afetada por suas preferências.

<b>COOKIES DE TERCEIROS</b>
Em alguns casos especiais, também usamos cookies fornecidos por terceiros confiáveis. A seção a seguir detalha quais cookies de terceiros você pode encontrar através deste site.

•	Este site usa o Google Analytics, que é uma das soluções de análise mais difundidas e confiáveis da Web, para nos ajudar a entender como você usa o site e como podemos melhorar sua experiência. Esses cookies podem rastrear itens como quanto tempo você gasta no site e as páginas visitadas, para que possamos continuar produzindo conteúdo atraente.

Para mais informações sobre cookies do Google Analytics, consulte a página oficial do Google Analytics.

•	As análises de terceiros são usadas para rastrear e medir o uso deste site, para que possamos continuar produzindo conteúdo atrativo. Esses cookies podem rastrear itens como o tempo que você passa no site ou as páginas visitadas, o que nos ajuda a entender como podemos melhorar o site para você.
•	Periodicamente, testamos novos recursos e fazemos alterações subtis na maneira como o site se apresenta. Quando ainda estamos testando novos recursos, esses cookies podem ser usados para garantir que você receba uma experiência consistente enquanto estiver no site, enquanto entendemos quais otimizações os nossos usuários mais apreciam.
•	À medida que vendemos produtos, é importante entendermos as estatísticas sobre quantos visitantes de nosso site realmente compram e, portanto, esse é o tipo de dados que esses cookies rastrearão. Isso é importante para você, pois significa que podemos fazer previsões de negócios com precisão que nos permitem analisar nossos custos de publicidade e produtos para garantir o melhor preço possível.

<b>Mais informações</b> - Esperemos que esteja esclarecido e, como mencionado anteriormente, se houver algo que você não tem certeza se precisa ou não, geralmente é mais seguro deixar os cookies ativados, caso interaja com um dos recursos que você usa em nosso site.

<b>COOKIE DOUBLECLICK DART</b>
O Google faz uso de cookies DART em nosso site, a utilização deles é importante para que a empresa possa exibir anúncios para o usuário levando em conta outros sites que a pessoa navegou anteriormente na internet, vale destacar que os usuários podem realizar a desativação de tais cookies acessando diretamente a política de privacidade do Google.

<b>ANÁLISE DE DADOS</b>
A nossa empresa utiliza o serviço do Google Analytics para mensurar, calcular e analisar todo o tráfego que recebe no site www.abraceumacausa.com.br esta ferramenta coleta e processa os dados de acordo com a sua própria política, partindo do pressuposto da aceitação do usuário final, a política está disponível nos links abaixo:
•	<a href="https://www.google.com/intl/pt-BR/policies/privacy/partners/" target="blank">https://www.google.com/intl/pt-BR/policies/privacy/partners/</a>
•	<a href="https://policies.google.com/privacy" target="blank">https://policies.google.com/privacy</a>
•	<a href="https://policies.google.com/terms" target="blank">https://policies.google.com/terms</a>

<h3>COMPROMISSO DO USUÁRIO</h3>
O usuário se compromete a fazer uso adequado dos conteúdos e da informação que o Abrace uma Causa oferece no site e com caráter enunciativo, mas não limitativo:
• A) Não se envolver em atividades que sejam ilegais ou contrárias à boa fé a à ordem pública;
• B) Não divulgar conteúdo ou propaganda de natureza racista, xenofóbica, apostas online, pornografia ilegal, de apologia ao terrorismo ou contra os direitos humanos;
• C) Não causar danos aos sistemas físicos (hardwares) e lógicos (softwares) do Abrace uma Causa, de seus fornecedores ou terceiros, para introduzir ou disseminar vírus informáticos ou quaisquer outros sistemas de hardware ou software que sejam capazes de causar danos anteriormente mencionados.
<h3>RECEBIMENTO DE E-MAILS:</h3>
O envio de e-mail de divulgações e newsletters serão realizados aos usuários que podem a qualquer momento voluntariamente desativar estes e-mails, exceto aqueles que são imprescindíveis para garantir a segurança e a transparência da plataforma. (Exemplo: e-mail de confirmação de conta, extratos, etc.)
<h3>DIVULGAÇÃO DE E-MAILS PARA TERCEIROS:</h3>
A Abrace Uma Causa compromete-se a não compartilhar seu e-mail com terceiros, exceto diante de expressão de vontade do usuário em atualizar-se diante de campanhas de projetos que incentiva.
<h3>SEGURANÇA CIBERNÉTICA:</h3>
A plataforma da Abrace Uma Causa pode reter o endereço de IP do usuário com o objetivo de identificá-lo em caso de tentativa de fraude.
Além disso, o site possui monitoramento 24 (vinte e quatro) horas, bem como Backup incremental diário, redundância de armazenamento de informações, servidor protegido por firewall, utilização de criptografia para armazenamento de senhas e utilização de protocolo SSL para comunicação segura entre servidor e usuário.
<h3>DADOS PESSOAIS</h3>
Neste ato Você autoriza o tratamento de seus respectivos dados pessoais para criação de conta na plataforma para identificação de quem é o doador e para prestação de contas (recibo, informe, consulta às doações feitas, emissão de segunda via de boleto, etc), sendo certo que tais dados pessoais serão compartilhados com repartições públicas e/ou Fundo e/ou Entidade Apoiada com a finalidade de tão somente informar a titularidade da doação realizada. 
O consentimento poderá ser revogado a qualquer momento mediante manifestação expressa do titular a Abrace Uma Causa, sendo certo que, nesta hipótese, você não poderá mais participar como doador das campanhas. O armazenamento dos seus dados pessoais é feito na nuvem com utilização de criptografia desde a transmissão dos dados do aparelho eletrônico do usuário até a gravação e manutenção no servidor.
A Abrace Uma Causa não armazena dados de cartão, os quais são processados via gateway diretamente.

<h3>DIREITOS DO TITULAR</h3>
O titular do Dado Pessoal possui os seguintes direitos em relação aos seus dados, na medida em que tais direitos sejam reconhecidos pelas leis aplicáveis:
Confirmação da existência de tratamento: confirmação da existência de tratamento dos Dados mantidos pela Abrace uma Causa;
Acesso aos dados: acesso aos Dados coletados pela Abrace uma Causa , com exceção dos casos de proteção de segredo comercial e indústria;
Correção de dados: solicitação da correção dos Dados que contenham informações incompletas, desatualizadas ou errôneas;
Anonimização, bloqueio ou eliminação de dados: anonimização, bloqueio ou eliminação dos Dados desnecessários, excessivos ou tratados em desconformidade com o disposto na legislação aplicável. A anonimização se dará considerando a utilização de meios técnicos razoáveis e disponíveis na ocasião do tratamento dos Dados;
Portabilidade: portabilidade dos Dados Pessoais mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial.
Eliminação dos dados: eliminação dos Dados Pessoais tratados com o consentimento do titular, exceto nas hipóteses previstas na legislação vigente, nesse sentido, sujeito aos requerimentos legais locais, a Abrace uma Causa pode manter o Dado Pessoal caso: (i) seja legalmente obrigada a mantê-los, (ii) para o cumprimento de leis e/ou regulamentos que assim determinem; (iii) necessite dos Dados para estabelecer, exercer ou defender reivindicações legais; e (iii) necessite manter o controle dos Dados por razões de saúde pública.
Informação sobre o compartilhamento de Dados: recebimento de informação sobre os seus Dados Pessoais que são compartilhados com entidades públicas e privadas.
Revogação de consentimento: revogação do consentimento dado, a qualquer momento, mediante requerimento expresso do titular. O procedimento de revogação será sempre gratuito e facilitado; e

<h3>DADOS DE CONTATO</h3>
Para esclarecer alguma dúvida adicional, favor encaminhar uma mensagem para o e- mail: dpo@abraceumacausa.com.br
<h3>ALTERAÇÕES NA PRESENTE POLÍTICA</h3>
Poderemos ocasionalmente efetuar alterações na presente Política.
Quando realizarmos alterações relevantes na presente Política, você será devidamente notificado, conforme as circunstâncias, por exemplo, exibindo um aviso na página principal de https://abraceumacausa.com.br ou enviando-lhe um e-mail para contatos cadastrados em nossos serviços.
<h3>DISPOSIÇÕES FINAIS</h3>
A Abrace uma Causa, a seu exclusivo critério, poderá modificar os termos e condições constantes no presente Aviso, incluindo, mas não se limitando, às hipóteses decorrentes do cumprimento de legislação, às alterações nos produtos e serviços, ou pela implantação de novas ferramentas tecnológicas. Para maior segurança e dentro do princípio da transparência, eventuais atualizações deste Aviso de Privacidade serão disponibilizadas para conhecimento dos titulares no site da Abrace uma Causa (https://abraceumacausa.com.br). A Abrace uma Causa não se responsabiliza por problemas decorrentes de demora, interrupção ou bloqueio nas transmissões dos dados enviados no seu endereço eletrônico.
Essa Política foi atualizada pela última vez em 10 de maio de 2021.
            </pre>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </Modal>
</template>
<script type="plain/text">
import Modal from "@/components/Modal.vue";

export default {
  props: ["value"],
  components: {
    Modal
  },
  data() {
    return {
      dialog: false
    };
  },
  watch: {
    value() {
      this.dialog = this.value;
    }
  },
  methods: {
    toggleDialog() {
      this.dialog = !this.dialog;
      this.$emit("input", this.dialog);
    }
  }
};
</script>
