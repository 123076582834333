export default class CreditCardModel {
    constructor() {
        this.subscriptionId = '';
        this.planId = '';
        this.paymentMethod = '';
        this.cardId = '';
        this.cardNumber = '';
        this.cardName = '';
        this.expirationDate = '';
        this.CVV = '';
    }
}