import { format, parse } from "date-fns";
export default class FormatterHelper {
	formatMoney(amount, decimalCount = 2, decimal = ",", thousands = ".") {
		try {
			decimalCount = Math.abs(decimalCount);
			decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

			const negativeSign = amount < 0 ? "-" : "";

			let i = parseInt(
				(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
			).toString();
			let j = i.length > 3 ? i.length % 3 : 0;

			return (
				negativeSign +
				(j ? i.substr(0, j) + thousands : "") +
				i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
				(decimalCount
					? decimal +
					Math.abs(amount - i)
						.toFixed(decimalCount)
						.slice(2)
					: "")
			);
		} catch (e) {
			console.log(e);
		}
	}
	formatShortNumber(n, d) {
		var k = n = Math.floor(n);
		if (n < 1000) return (n.toString().split("."))[0];
		if (d !== 0) d = d || 1;
		function shorten(a, b, c) {
			var d = a.toString().split(".");
			if (!d[1] || b === 0) {
				return d[0] + c
			} else {
				return d[0] + "," + d[1].substring(0, b) + c;
			}
		}
		k = n / 1e15; if (k >= 1) return shorten(k, d, " quad");
		k = n / 1e12; if (k >= 1) return shorten(k, d, " tri");
		k = n / 1e9; if (k >= 1) return shorten(k, d, " bi");
		k = n / 1e6; if (k >= 1) return shorten(k, d, " mi");
		k = n / 1e3; if (k >= 1) return shorten(k, d, " mil");
	}

	writeNumbersAsText(value,c = true) {
		if (value) {
			var ex = [
				["Zero", "Um", "Dois", "Três", "Quatro", "Cinco", "Seis", "Sete", "Oito", "Nove", "Dez", "Onze", "Doze", "Treze", "Quatorze", "Quinze", "Dezesseis", "Dezessete", "Dezoito", "Dezenove"],
				["Dez", "Vinte", "Trinta", "Quarenta", "Cinquenta", "Sessenta", "Setenta", "Oitenta", "Noventa"],
				["Cem", "Cento", "Duzentos", "Trezentos", "Quatrocentos", "Quinhentos", "Seiscentos", "Setecentos", "Oitocentos", "Novecentos"],
				["Mil", "Milhão", "Bilhão", "Trilhão", "Quadrilhão", "Quintilhão", "Sextilhão", "Setilhão", "Octilhão", "Nonilhão", "Decilhão", "Undecilhão", "Dodecilhão", "Tredecilhão", "Quatrodecilhão", "Quindecilhão", "Sedecilhão", "Septendecilhão", "Octencilhão", "Nonencilhão"]
			];
			var a, n, v, i, n = value.toString().replace(c ? /[^,\d]/g : /\D/g, "").split(","), e = " e ", $ = "real", d = "centavo", sl;
			for (var f = n.length - 1, l, j = -1, r = [], s = [], t = ""; ++j <= f; s = []) {
				j && (n[j] = (("." + n[j]) * 1).toFixed(2).slice(2));
				if (!(a = (v = n[j]).slice((l = v.length) % 3).match(/\d{3}/g), v = l % 3 ? [v.slice(0, l % 3)] : [], v = a ? v.concat(a) : v).length) continue;
				for (a = -1, l = v.length; ++a < l; t = "") {
					if (!(i = v[a] * 1)) continue;
					i % 100 < 20 && (t += ex[0][i % 100]) ||
						i % 100 + 1 && (t += ex[1][(i % 100 / 10 >> 0) - 1] + (i % 10 ? e + ex[0][i % 10] : ""));
					s.push((i < 100 ? t : !(i % 100) ? ex[2][i == 100 ? 0 : i / 100 >> 0] : (ex[2][i / 100 >> 0] + e + t)) +
						((t = l - a - 2) > -1 ? " " + (i > 1 && t > 0 ? ex[3][t].replace("ão", "ões") : ex[3][t]) : ""));
				}
				a = ((sl = s.length) > 1 ? (a = s.pop(), s.join(" ") + e + a) : s.join("") || ((!j && (n[j + 1] * 1 > 0) || r.length) ? "" : ex[0][0]));
				a && r.push(a + (c ? (" " + (v.join("") * 1 > 1 ? j ? d + "s" : (/0{6,}$/.test(n[0]) ? "de " : "") + $.replace("l", "is") : j ? d : $)) : ""));
			}
			return r.join(e);
		}
		return "";
	}

	formatThousand(x) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
	}

	formatPostalCode(postalCode) {
		for (let i = 0; i < 8; i++) {
			postalCode = '0' + postalCode;
		}

		postalCode = postalCode.substr(postalCode.length - 8);
		return postalCode.replace(/^(\d{5})(\d{2})/, "$1-$2");
	}

	formatCpf(cpf) {
		for (let i = 0; i < 11; i++) {
			cpf = '0' + cpf;
		}

		cpf = cpf.substr(cpf.length - 11);
		return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1\.$2\.$3-$4");

	}

	formatSlider(current, total, minimum = 0, maximum = 100) {
		let v = 0;
		if (total > 0) {
			v = Math.round((current / total) * 100);
			if (v > 100) v = Math.min(maximum, Math.max(minimum, v));
		}else if(current > 0){
			v = 100;
		}
		return v;
	}

	formatCnpj(cnpj) {
		for (let i = 0; i < 14; i++) {
			cnpj = '0' + cnpj;
		}

		cnpj = cnpj.substr(cnpj.length - 14);
		return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1\.$2\.$3/$4-$5");

	}

	formatDate(date) {
		if (date != null) {
			return format(date, "DD/MM/YYYY", {
				locale: require("date-fns/locale/pt")
			});
		}
		return date || '-';
	}

	formatDateAndHour(date) {
		if (date != null) {
			return format(date, "DD/MM/YYYY HH:mm", {
				locale: require("date-fns/locale/pt")
			});
		}
		return date;
	}

	formatDateWithPattern(date, pattern) {
		if (date != null) {
			return format(date, pattern);
		}
		return date;
	}

	parseDate(text, pattern) {
		return parse(text, pattern, new Date());
	}

}