import BaseCrudService from "./baseCrud.service";
import { Routes } from "@/scripts/models/enums/routes.enum.js";

export default class PersonService extends BaseCrudService {

    constructor() {
        super('Person');
    }

    create(person) {
        //Se criação de um usuário através do facebook
        if (person.user.facebookId) {
            return this.postRequest('CreateFromFacebook', person);
        }
        //Se é criação de um usuário através do g-mail
        else if (person.user.googleId) {
            return this.postRequest('CreateFromGoogle', person);
        }
        else {
            super.create(person)
                .then(this.onPersonCreate.bind(this));
        }
    }

    getByApiList( person ){
        return this.postRequest('GetByApiList', person);
    }

    createFromSimplifiedCheckout(person){
        return super.create(person);
    }

    getOrCreateBySimplifiedDonation(person){
        return this.postRequest('GetOrCreateBySimplifiedDonation', person);
    }

    onPersonCreate(person) {
        this.router.push({
            name: Routes.web.LoginVerifyAccount,
            query: { email: person.email }
        });
    }

    register(person, callback){
        super.create(person).then(callback)
    }

    registerAndLogin(person, callback){
        this.postRequest('CreateAndLogin', person).then(callback)
    }

    getProfile(callback) {
        this.getRequest('GetProfile').then(callback);
    }

    checkVerificationCode(obj, callback) {
        this.getRequest('CheckVerificationCode', { email: obj.email, verificationCode: obj.verificationCode }).then(callback);
    }

    resendVerificationCode(callback, person) {
        this.postRequest('ResendVerificationCode', person).then(callback);
    }

    saveProfile(callback, person) {
        this.postRequest('SaveProfile', person).then(callback);
    }

    updateEmailByTempHash(tempHash, person) {
        return this.putRequest('UpdateEmailByTempHash', {tempHash: tempHash, person: person});
    }

    updatePersonInfo(data) {
        return this.postRequest('UpdatePersonInfo', data);
    }

    loadPeopleInformation() {
        return this.getRequest('LoadPeopleInformation');
    }

    loadPagedPeopleInformation(pageNumber, loadNumber, name, email, cpf) {
        return this.getRequest('LoadPagedPeopleInformation', { pageNumber, loadNumber, name, email, cpf });
    }

    loadPaginationInfo(pageNumber, loadNumber, name, email, cpf) {
        return this.getRequest('LoadPaginationInfo', { pageNumber, loadNumber, name, email, cpf });
    }

    listPersonByEntity(entityId) {
        return this.getRequest('ListPersonByEntityWithoutLoggedUser', { entityId: entityId });
    }

    listPersonSignReceiptByEntity(entityId) {
        return this.getRequest('ListPersonSignReceiptByEntity', { entityId: entityId });
    }
    listPersonWithoutSignReceiptByEntity(entityId) {
        return this.getRequest('ListPersonWithoutSignReceiptByEntity', { entityId: entityId });
    }

    existsPresidentByEntity(entityId) {
        return this.getRequest('ExistsPresidentByEntity', { entityId: entityId });
    }

    existsRepresentativeByEntity(entityId) {
        return this.getRequest('ExistsRepresentativeByEntity', { entityId: entityId });
    }

    getLoggedPersonUser(entityId) {
        return this.getRequest('GetLoggedPersonUser', { entityId: entityId });
    }

    getRolesByEntity(entityId) {
        return this.getRequest('GetRolesByEntity', { entityId: entityId });
    }

    findByCPF(cpf, entityId) {
        return this.getRequest('FindByCPF', { cpf: cpf, entityId: entityId });
    }

    existsWithOptinByCpf(cpf){
        return this.getRequest('ExistsWithOptinByCpf', { cpf: cpf });
    }

    findByCPFAndEmail(cpf, email){
        return this.getRequest('FindByCPFAndEmail', { cpf: cpf, email: email });
    }

    saveUserPerson(userPerson) {
        return this.postRequest('SaveUserPerson', userPerson);
    }

    saveUserPersonSilent(userPerson) {
        return this.postRequest('SaveUserPerson', userPerson, false, false);
    }

    validatePossibilityToAddPerson(person){
        return this.postRequest('ValidatePossibilityToAddPerson', person);
    }

    listPermissionIdInUserPermissionEntityByEntityId(entityId, userId){
        return this.getRequest('ListPermissionIdInUserPermissionEntityByEntityId', {entityId: entityId, userId: userId});
    }

    getPersonByUserId(userId){
        return this.getRequest('GetPersonByUserId', {userId: userId});
    }

    getLoggedPersonAddress(){
        return this.getRequest('GetLoggedPersonAddress');
    }

    uploadMemberGetMember(formData){
        return this.postFileRequest('UploadMemberGetMember', formData);
    }

    finAllMgmByCampaignId(campaignId){
        return this.getRequest('FinAllMgmByCampaignId', {campaignId: campaignId});
    }

    findPersonByCodeIndication(mgmCode){
        return this.getRequest('FindPersonByCodeIndication', {mgmCode: mgmCode});
    }
}